import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Software/Other_Platforms/NavButtons';
import ForumBox from 'components/Software/Other_Platforms/Homee/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "homee Z-Wave Bridge",
  "path": "/Software/Other_Platforms/Homee/",
  "dateChanged": "2019-04-25",
  "author": "Mike Polinowski",
  "excerpt": "In the following tutorial we will add a Fibaro Motion Sensor and an ABUS Indoor Siren to our network. Both will be connected using the Z-Wave wireless communications protocol with the help of the homee Gateway and Z-Wave Bridge Modul. The gateway will then function as a bridge between the wireless Z-Wave network and our INSTAR Full HD Camera.",
  "social": "/images/Search/P_SearchThumb_homee.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Other_homee_white.webp",
  "chapter": "Software",
  "category": "smarthome",
  "type": "homee"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='homee Z-Wave Bridge' dateChanged='2019-04-25' author='Mike Polinowski' tag='INSTAR IP Camera' description='In the following tutorial we will add a Fibaro Motion Sensor and an ABUS Indoor Siren to our network. Both will be connected using the Z-Wave wireless communications protocol with the help of the homee Gateway and Z-Wave Bridge Modul. The gateway will then function as a bridge between the wireless Z-Wave network and our INSTAR Full HD Camera.' image='/images/Search/P_SearchThumb_homee.png' twitter='/images/Search/P_SearchThumb_homee.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/Other_Platforms/Homee/' locationFR='/fr/Software/Other_Platforms/Homee/' crumbLabel="homee" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h1>
    <h2 {...{
      "id": "other",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#other",
        "aria-label": "other permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Other`}</h2>
    <h3 {...{
      "id": "homee",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#homee",
        "aria-label": "homee permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`homee`}</h3>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#adding-z-wave-components"
        }}>{`Adding Z-Wave Components`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#camera-alarmserver-and-homee-webhook"
        }}>{`Camera Alarmserver and homee Webhook`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#instar-ip-cameras-and-external-z-wave-motion-sensors"
        }}>{`INSTAR IP Cameras and external Z-Wave Motion Sensors`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#daynight-position"
        }}>{`Day/Night Position`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#deactivate-alarm-when-you-are-home"
        }}>{`Deactivate Alarm when you are Home`}</a></li>
    </ul>
    {/* /TOC */}
    <p>{`In the following tutorial I want to add a `}<a parentName="p" {...{
        "href": "https://hom.ee/products/devices/fibaromotionsensor-049-motion"
      }}>{`Fibaro Motion Sensor`}</a>{` and an `}<a parentName="p" {...{
        "href": "https://hom.ee/products/devices/abusz-waveinnensirene-001-siren"
      }}>{`ABUS Indoor Siren`}</a>{` to our network. Both will be connected using the `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Z-Wave"
      }}>{`Z-Wave wireless communications protocol`}</a>{` with the help of the `}<a parentName="p" {...{
        "href": "https://store.hom.ee/collections/all/products/homee-z-wave-bundle"
      }}>{`homee Gateway and Z-Wave Bridge Modul`}</a>{`. The gateway will then function as a bridge between the wireless Z-Wave network and our INSTAR Full HD Camera.`}</p>
    <h2 {...{
      "id": "adding-z-wave-components",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#adding-z-wave-components",
        "aria-label": "adding z wave components permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Adding Z-Wave Components`}</h2>
    <p>{`To add the Z-Wave devices I first login to the `}<strong parentName="p">{`homee`}</strong>{` administrator dashboard `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`https://my.hom.ee`}</code>{` - alternatively you can also use the `}<a parentName="p" {...{
        "href": "https://itunes.apple.com/ro/app/homee/id611086136?mt=8&ign-mpt=uo%3D4"
      }}>{`homee iOS app`}</a>{` - the `}<a parentName="p" {...{
        "href": "https://play.google.com/store/apps/details?id=com.codeatelier.homee.smartphone&hl=en"
      }}>{`Android app`}</a>{` was not working at the time of writing:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "905px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/917484794cb4eb5efa8fe6d41ad66596/65d79/Homee_Adding_Sensors_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "74.78260869565217%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABRElEQVQoz72Py07CQBRA+18+kFI6c+dBpy+KMeFp0kg7sKAUDeIP4Ep8bG2RHTu68POMEBIrEqILT85iMpmTO1exXK8i7DKmiHAdaEnHJ2el44K6z6NTtaBqGmJu7UJZLpf306nv+1LKMAyllL1DyE/CIAgUy7KEEMafUDDGkAdvgUMoJA8A2LbteZ7jODQP2UHZveKcM8boDodjADBNk3MOAP87eRNvnv7+24RQxoRpGkJQxtbSjWSn/2FnrGqsjKimo6KKiireCgjtjykFhHjVabw+1JNZPZm150+dt+dG+thIZs3Fi+m3oax/nZ+PdcTPq61V0srSZpZ2svQymzezpLVK2u8LS/qglffEhGAAIUQ8iEbRcBQNJ+Px3e3kehjHg+gmHtW8GsZ4784AUDGMqyDYKPs92e931+duGDqu+y3+ALRAIr8u9lCmAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/917484794cb4eb5efa8fe6d41ad66596/e4706/Homee_Adding_Sensors_01.avif 230w", "/en/static/917484794cb4eb5efa8fe6d41ad66596/d1af7/Homee_Adding_Sensors_01.avif 460w", "/en/static/917484794cb4eb5efa8fe6d41ad66596/8df31/Homee_Adding_Sensors_01.avif 905w"],
              "sizes": "(max-width: 905px) 100vw, 905px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/917484794cb4eb5efa8fe6d41ad66596/a0b58/Homee_Adding_Sensors_01.webp 230w", "/en/static/917484794cb4eb5efa8fe6d41ad66596/bc10c/Homee_Adding_Sensors_01.webp 460w", "/en/static/917484794cb4eb5efa8fe6d41ad66596/4d060/Homee_Adding_Sensors_01.webp 905w"],
              "sizes": "(max-width: 905px) 100vw, 905px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/917484794cb4eb5efa8fe6d41ad66596/81c8e/Homee_Adding_Sensors_01.png 230w", "/en/static/917484794cb4eb5efa8fe6d41ad66596/08a84/Homee_Adding_Sensors_01.png 460w", "/en/static/917484794cb4eb5efa8fe6d41ad66596/65d79/Homee_Adding_Sensors_01.png 905w"],
              "sizes": "(max-width: 905px) 100vw, 905px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/917484794cb4eb5efa8fe6d41ad66596/65d79/Homee_Adding_Sensors_01.png",
              "alt": "Homee INSTAR Cameras",
              "title": "Homee INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Go to the `}<strong parentName="p">{`Devices`}</strong>{` tab and click the `}<strong parentName="p">{`+`}</strong>{` button to add a new Z-Wave device:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/da65e085825e57bfa9b62366143b5bd4/82b28/Homee_Adding_Sensors_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "43.913043478260875%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAA7CAAAOwgEVKEqAAAABTUlEQVQoz6XOzyuDcQDHcf+NOcmwPc0e5qKwlYQdFknIjQsHJLtKCTlxc5AcbLcRyY9mWJlne2g3bY/Vnuf7bA+XHd/aiBOTHd59bq8+dduhIbZDfnbCfg7Plkg83KMoSeLxODexGA+KQioW4/rigg6PB0mScLlcP1Z3cDnK/nmAvXM/kbtFNC2Lpmlksxkyz89kikWMuXmSm1vIPd1IDsfvYOh2hGh6haPENKfKPIWCiWl+JgTi1eItofB4eYXc2Vn9Yfh2kujTGkf3s5wkFhHCwDC+0/N5rFIJNZ1Gdrurg8epKcpFkmNcpYOfoKhgogwaBlaxiJpKIctydXB5vZ/gxgALq71s7c58QEJ8ra7rWJaFqqp/A9vsEnKzi2ZbI8ODgQpQE9jX2kJ3u4yjwca414v1kkM3zf+DviY7XU4JZ72NCa8PK5fDqAF8BwPWxsyNFR7OAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/da65e085825e57bfa9b62366143b5bd4/e4706/Homee_Adding_Sensors_02.avif 230w", "/en/static/da65e085825e57bfa9b62366143b5bd4/d1af7/Homee_Adding_Sensors_02.avif 460w", "/en/static/da65e085825e57bfa9b62366143b5bd4/7f308/Homee_Adding_Sensors_02.avif 920w", "/en/static/da65e085825e57bfa9b62366143b5bd4/164ea/Homee_Adding_Sensors_02.avif 931w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/da65e085825e57bfa9b62366143b5bd4/a0b58/Homee_Adding_Sensors_02.webp 230w", "/en/static/da65e085825e57bfa9b62366143b5bd4/bc10c/Homee_Adding_Sensors_02.webp 460w", "/en/static/da65e085825e57bfa9b62366143b5bd4/966d8/Homee_Adding_Sensors_02.webp 920w", "/en/static/da65e085825e57bfa9b62366143b5bd4/c4004/Homee_Adding_Sensors_02.webp 931w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/da65e085825e57bfa9b62366143b5bd4/81c8e/Homee_Adding_Sensors_02.png 230w", "/en/static/da65e085825e57bfa9b62366143b5bd4/08a84/Homee_Adding_Sensors_02.png 460w", "/en/static/da65e085825e57bfa9b62366143b5bd4/c0255/Homee_Adding_Sensors_02.png 920w", "/en/static/da65e085825e57bfa9b62366143b5bd4/82b28/Homee_Adding_Sensors_02.png 931w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/da65e085825e57bfa9b62366143b5bd4/c0255/Homee_Adding_Sensors_02.png",
              "alt": "Homee INSTAR Cameras",
              "title": "Homee INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Select Z-Wave - `}<strong parentName="p">{`Note`}</strong>{` you need to have the `}<strong parentName="p">{`Z-Wave`}</strong>{` cube installed on your `}<strong parentName="p">{`homee`}</strong>{` gateway. `}<strong parentName="p">{`homee`}</strong>{` also offers a `}<strong parentName="p">{`Zigbee`}</strong>{` and `}<strong parentName="p">{`EnOcean`}</strong>{` cube that, of course, only works with `}<a parentName="p" {...{
        "href": "https://hom.ee/products"
      }}>{`devices that support those protocols`}</a>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "917px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/688b49c6d98ae2d1587a49f329140f21/59000/Homee_Adding_Sensors_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "45.65217391304348%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAA7CAAAOwgEVKEqAAAACMklEQVQoz23R3UtTcRjA8fPvWDeJpbFe7tymZy9H13HCWLKtKL1oC1ORYehFdwVloqAgszTNUDdl6Syjkmgals2tNYPS6Vxu58ytf+AbncIgu/jyXP0+PDw/YTDYwFDQzlCogamlTj6svyMW22BtbY2VaJSPsRgbb6MszS8wOzdHJBIhFAoxPT3NzMzMkYQny008fuVg/GUj4ZV20jtf2d3ZJZ1Ok/62RbqgkrvfT+zhKJe91/C4XHg8Hlwu12Fut/swIbh6keXkLZ7FWllcb0VR91HVAqqqaimFAqVsli+JBKLJhMFg0LLZbNjtdiTJit5oxFhjRK/XIwRXLvHm022er3cSed9GLp9FySvk83mtXC7HwY8Sqc1N6uokzGYzFosFSZKQZRmrxYrdZNamyWRCWIxfYTF+lYUND68/+8kpWRRF1TDlD1o8OCCVSmmQKIoa+utxjViDZDbyqLwCWTRgEGsRuu/V09N7Af8dE3eHm/m+v/cXVH5vWiqVSCQStLS0EAgEGBkJ0N83SHNHIwOrEuMvrAxEbchOEeFM+WnOV+g4WXYC2VRPJpPRbvcvGI/H8Xq92g+HZoOMPZjE1+1gdLee4S2Z0W0Zu0tEsOt0GM+dper4MZzV1RS20+QLhf+CPp+PcDjM/PxTJsamuN7jYGLfxmTGwfheA41uEcF2qhJ9ZRW6smM0GYwo6aNgsVgkmUzidDrx+/103eyi7UYHDk8d7X0WOvvstPVakeRafgIUn/1StI8jLAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/688b49c6d98ae2d1587a49f329140f21/e4706/Homee_Adding_Sensors_03.avif 230w", "/en/static/688b49c6d98ae2d1587a49f329140f21/d1af7/Homee_Adding_Sensors_03.avif 460w", "/en/static/688b49c6d98ae2d1587a49f329140f21/bea5c/Homee_Adding_Sensors_03.avif 917w"],
              "sizes": "(max-width: 917px) 100vw, 917px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/688b49c6d98ae2d1587a49f329140f21/a0b58/Homee_Adding_Sensors_03.webp 230w", "/en/static/688b49c6d98ae2d1587a49f329140f21/bc10c/Homee_Adding_Sensors_03.webp 460w", "/en/static/688b49c6d98ae2d1587a49f329140f21/f91b9/Homee_Adding_Sensors_03.webp 917w"],
              "sizes": "(max-width: 917px) 100vw, 917px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/688b49c6d98ae2d1587a49f329140f21/81c8e/Homee_Adding_Sensors_03.png 230w", "/en/static/688b49c6d98ae2d1587a49f329140f21/08a84/Homee_Adding_Sensors_03.png 460w", "/en/static/688b49c6d98ae2d1587a49f329140f21/59000/Homee_Adding_Sensors_03.png 917w"],
              "sizes": "(max-width: 917px) 100vw, 917px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/688b49c6d98ae2d1587a49f329140f21/59000/Homee_Adding_Sensors_03.png",
              "alt": "Homee INSTAR Cameras",
              "title": "Homee INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`First I select the motion sensor from the following list:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "915px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c625f975009393670cea16feb46e4c0a/4255a/Homee_Adding_Sensors_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "43.913043478260875%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAA7CAAAOwgEVKEqAAAAB9UlEQVQoz3XPz0uTcQCA8feP2c1OkVseyjoWCNned27vd++77d3et+VsdbCCokN07FBEQdScMZLoUAbZD0eaBNbaYc1pIMk7rZbkflm5CXZ5whcRYnh4rh94pHvPfGyXeWkwm59gvvSZYvETuVyOQqFAaW6efGGWR8sa4z9NnqwajH2X90wamxI8fOPn8UyYucVpvq5UKJdtlpaWsG0b2y7zrbLC6IurnL8+wJXbIS7cUJwu3vR1JE0Wz/J24TLjecHyap5f6y0ajTrNZtOpVquxtfWX+3cf0HesH9MYJBw0CYkYvpMC+UQAuV9F2UmaWbjGxy93eJrTsCs5mo3fVGtVB9pubW2NdrvNSDqF4pMZTJzGsmJEYwahkIbQBEE9iNCDBHWBlC0lyZbO8KpoUf7xgWbjD7ValXq9/h+YyWTQdZ3h4WGSySTJoSGMeJxLio93+w9y63gfmmkipSdURicEI8/9LJbfs97sBDc3N0mn0yiKgmVZRKNRIpEIoXCYiBCc88rE/X6Cuo505Ggvh3sP0dPjYXp6io2NFtVqJ5hKpfB6vSQSCU5ZFrFYzEEHAgFkIRhQVQKBAJLH48HtdtPVtY9sNkurtTcoy4oDmqbpJIRwEHUnB3R7PHR3H8DlcjE5+doBt5G9lg3DQNM0p11QVXf7B2Qk9f/CBWU0AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c625f975009393670cea16feb46e4c0a/e4706/Homee_Adding_Sensors_04.avif 230w", "/en/static/c625f975009393670cea16feb46e4c0a/d1af7/Homee_Adding_Sensors_04.avif 460w", "/en/static/c625f975009393670cea16feb46e4c0a/6e770/Homee_Adding_Sensors_04.avif 915w"],
              "sizes": "(max-width: 915px) 100vw, 915px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c625f975009393670cea16feb46e4c0a/a0b58/Homee_Adding_Sensors_04.webp 230w", "/en/static/c625f975009393670cea16feb46e4c0a/bc10c/Homee_Adding_Sensors_04.webp 460w", "/en/static/c625f975009393670cea16feb46e4c0a/632b0/Homee_Adding_Sensors_04.webp 915w"],
              "sizes": "(max-width: 915px) 100vw, 915px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c625f975009393670cea16feb46e4c0a/81c8e/Homee_Adding_Sensors_04.png 230w", "/en/static/c625f975009393670cea16feb46e4c0a/08a84/Homee_Adding_Sensors_04.png 460w", "/en/static/c625f975009393670cea16feb46e4c0a/4255a/Homee_Adding_Sensors_04.png 915w"],
              "sizes": "(max-width: 915px) 100vw, 915px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c625f975009393670cea16feb46e4c0a/4255a/Homee_Adding_Sensors_04.png",
              "alt": "Homee INSTAR Cameras",
              "title": "Homee INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`And follow the instruction to pair the `}<a parentName="p" {...{
        "href": "https://hom.ee/products/devices/fibaromotionsensor-049-motion"
      }}>{`Fibaro Motion Sensor`}</a>{` - click on the green start button and then press the pair button on the motion sensor 3x fast:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "914px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3433b8ac792105cdd96027159be82d16/076ca/Homee_Adding_Sensors_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "58.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAA7CAAAOwgEVKEqAAAACr0lEQVQoz3WS20vTYRjH9+d4URSpqXMHZpr+TvOwzXSHiiLJJIq6iaiwwqggYhtBdGFQuQ4QCUVlUBTzsKg8dZBkbcqmbe6QZ7GbT/xe7XThxYf37vN83+f5Gm50N6Jz51kLA+97+Dj6meHhISKRCINDg4yOfOTth17uTvjoTrXwcHo/oaTrfxIuutYxhF656XrZxIM3+/g83sfkRJJ4PEY0GiUWixGLxUlMTXL94SkOnlY50uGgtV2mtV1Z46xC2zmVtvOawPBi5BivPp2k+52PRGqU2R8L5HJZ8vm8IJPJsLr6E/+Va5QUlSNX1+Koa8ZRu4sGu04jUpWdSptElU3GEP5ykchXP48ie5iYHiKXnSWTmREinXQ6zdLSEsFggBqpGq/XjdfnYbfPg8/rxuN10+xuwuVyoGoKhhejh+kZaeP5yEEmvw+Rz80JYTab/SNcXl7G7/djsVjQNA1ZkqhUFIEiSciKgizLSJKEofOxm84nHsG3yUHy+Y2FZrMZTVWpVBVabRW02SrYoa5JdZkuNdhsViwWEyZzOQMDA8zPzzMzs0FCs4VaVcUoqdzcXExoUyFlNSrqukwkLNleQlFRMVu2bKW3t5eFhQUh/HeHf4RWC3KVwr4LdfR0WHl9xsShQD3VO9aEImFZWRmFhdsoKCggHA6zuLgoRLlcTqTU5SsrKwQCfkxGMy6PRueYk1CiiVC8mVsTTjwtKjsr9V3KGIxGIyaTCf3t7+8XVUkmk0xNTQkSiYQYEggGMJaa2X3Izu24g66oj1B0L10JJwdOaKI2ii7UF/1X2Cdk4+PjAr3cY2NjYkgwGFw7iqZy+Wk999NO7qWdBMIN2OtlpJr1o1itViErLS0VCefm5kil0+KrOqlUSvTwd20USRWCo5fsHL9qp36XTHXV36P8AjgPi39GTg/PAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3433b8ac792105cdd96027159be82d16/e4706/Homee_Adding_Sensors_05.avif 230w", "/en/static/3433b8ac792105cdd96027159be82d16/d1af7/Homee_Adding_Sensors_05.avif 460w", "/en/static/3433b8ac792105cdd96027159be82d16/6ab89/Homee_Adding_Sensors_05.avif 914w"],
              "sizes": "(max-width: 914px) 100vw, 914px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3433b8ac792105cdd96027159be82d16/a0b58/Homee_Adding_Sensors_05.webp 230w", "/en/static/3433b8ac792105cdd96027159be82d16/bc10c/Homee_Adding_Sensors_05.webp 460w", "/en/static/3433b8ac792105cdd96027159be82d16/38f11/Homee_Adding_Sensors_05.webp 914w"],
              "sizes": "(max-width: 914px) 100vw, 914px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3433b8ac792105cdd96027159be82d16/81c8e/Homee_Adding_Sensors_05.png 230w", "/en/static/3433b8ac792105cdd96027159be82d16/08a84/Homee_Adding_Sensors_05.png 460w", "/en/static/3433b8ac792105cdd96027159be82d16/076ca/Homee_Adding_Sensors_05.png 914w"],
              "sizes": "(max-width: 914px) 100vw, 914px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3433b8ac792105cdd96027159be82d16/076ca/Homee_Adding_Sensors_05.png",
              "alt": "Homee INSTAR Cameras",
              "title": "Homee INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Once the sensor is paired you can give it a name and click `}<strong parentName="p">{`Next`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "917px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/54ccfd6192521425f1f8b1b1960cec48/59000/Homee_Adding_Sensors_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB1klEQVQoz5XLzWvTcADG8fwtvc17012WRJjMZFnavFbzK9MqelqGiHgUEc066ak7uaEgyFYn2BqKHTilqLNWO/fC2g7sNpUmWZKuLc2L9WCVZc6DNz+Hh+fyhWaz0dms8PB5/E0pu7lRWVtfKxaL5fLH1fKn0urbx9/OZYwLz4zLT/bBgs6ndSGtCwsav6iLGeMiNP8KPHp5Nl0Y39he1lRDVRuNRkPTNF3T9w11ZvH6xG3q2l1hUqYkeXRCJiWZnJwau5KIXJ3moKV1qVC98fSD+EV///PHL8e2Xdf1PM9x7H6/f+fW9GBwiBihmHCUHhPY8BmGiuKnqGEMHz55Gips3SzX72VK4zvqyueDd/W9WrVS29ndrW3XOp1OIjGFYgjLsRzPcTzLCxzHsTQTIUYJgsCh5Yr0Ykta2ry0p6/o7a8HLbPta7Vavd73ZDJJEATDMFT4UMRH0zTLsjRNQ/cV8YESm8sK1frrbrvXbFqGYZimaVlWt9tNpVIAiLFYTBRFAEA8Hj9/DAAAoSiCIEMwHMzn867rmscsy7JtR5ZlFEVIkiR8OI7/Of5CodBgCA4NDJzI5XJHcdNnmqbjHMYYhv2N/wHBQRiGg4FAQFEUz/P+K/4N9Mc0EAXH+DoAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/54ccfd6192521425f1f8b1b1960cec48/e4706/Homee_Adding_Sensors_06.avif 230w", "/en/static/54ccfd6192521425f1f8b1b1960cec48/d1af7/Homee_Adding_Sensors_06.avif 460w", "/en/static/54ccfd6192521425f1f8b1b1960cec48/bea5c/Homee_Adding_Sensors_06.avif 917w"],
              "sizes": "(max-width: 917px) 100vw, 917px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/54ccfd6192521425f1f8b1b1960cec48/a0b58/Homee_Adding_Sensors_06.webp 230w", "/en/static/54ccfd6192521425f1f8b1b1960cec48/bc10c/Homee_Adding_Sensors_06.webp 460w", "/en/static/54ccfd6192521425f1f8b1b1960cec48/f91b9/Homee_Adding_Sensors_06.webp 917w"],
              "sizes": "(max-width: 917px) 100vw, 917px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/54ccfd6192521425f1f8b1b1960cec48/81c8e/Homee_Adding_Sensors_06.png 230w", "/en/static/54ccfd6192521425f1f8b1b1960cec48/08a84/Homee_Adding_Sensors_06.png 460w", "/en/static/54ccfd6192521425f1f8b1b1960cec48/59000/Homee_Adding_Sensors_06.png 917w"],
              "sizes": "(max-width: 917px) 100vw, 917px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/54ccfd6192521425f1f8b1b1960cec48/59000/Homee_Adding_Sensors_06.png",
              "alt": "Homee INSTAR Cameras",
              "title": "Homee INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The sensor was successfully paired and is obviously already working:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "918px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6b31e598b96b1d508e0e741737b8ed1e/e0885/Homee_Adding_Sensors_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "78.69565217391303%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAA7CAAAOwgEVKEqAAAACtklEQVQ4y5WSy08TURTG589BAlI79jVtafsXGKSPuUNLax+piKCJcWFMSNGlCzVxhYmJLHzFSLsRiQY0mCAUnL4QNBAjUJq+p7ESN/KZe2tr6gtcfDlzJnd+853zXW4i6sRE1IXbMSemZscgyytIJpOIx5ewsLCA5ZU45KVVTGdu4sEOQWx3BE+yYUSzZzCVHcJU9jTrH+8E8GjbB+7hq0Hcn5Nwb86Fp4uXkd3dRj6fR6FQQKlUQrFUhFKpYXn1JUbH7RgZczKdi4g4P04wGhFZf3bMgdGIE1ws7sX82lVMy8N4kbqE+uc69upfUS6XUFNqUBQF3/b38S6zDl6lh5YXoOo6jp4uHnyPjlUqdY8Wqm4NuNhiEG/e38KMfBGziQi2yxms5eax+eEjNjY38GlrC1+qFaTltxCMAnR6LfQGHau/SacFN5MewvPMMJ6lQ3i9fgXFyi7ylR0oVQVVpYpKpYK9eh2pZBK9vb0wGo0wm80QBKFNBoMAo0kAd/2uEzcmXbh25wQmoxdQLBZRLlXZDnO5HKu1Wg2JRAIWi4XBKJhWk8nEauPZDIvNDE6rEWDQm3G0+xiI6GaB0DAKhWIrHAqUZRkajQYqlQpqtRo8z7Oe1mavVvPgbDYrjEYBnZ1H0N/f3wJSpw3wT4c2m63NodVqbT0zl2YTOPpSr9ejo6MDfX0n24BUFEiTTqVS8Pv9CIVC8Hg8EEWRiRDCJEkSBgYGwDX3oNPp2ME/OWwCfT4fg7rd7hakCaSwFpDapnugB/8FDAaDzKFESJs7KvHHD/4LSD90OByw2+1/FUfHpdeBpnQQkI4bCARaO2RjEwK/RBCQCLwSAScIjYt6GIcUFg6H2eht+yMEIYngFAUaDAZ2+w8CptNpFgqV1+ttA1INSgSehkPh0EAKasJ+DcUl0qAIvgMsBUS622Rv+gAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6b31e598b96b1d508e0e741737b8ed1e/e4706/Homee_Adding_Sensors_07.avif 230w", "/en/static/6b31e598b96b1d508e0e741737b8ed1e/d1af7/Homee_Adding_Sensors_07.avif 460w", "/en/static/6b31e598b96b1d508e0e741737b8ed1e/6d4a2/Homee_Adding_Sensors_07.avif 918w"],
              "sizes": "(max-width: 918px) 100vw, 918px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6b31e598b96b1d508e0e741737b8ed1e/a0b58/Homee_Adding_Sensors_07.webp 230w", "/en/static/6b31e598b96b1d508e0e741737b8ed1e/bc10c/Homee_Adding_Sensors_07.webp 460w", "/en/static/6b31e598b96b1d508e0e741737b8ed1e/56bc7/Homee_Adding_Sensors_07.webp 918w"],
              "sizes": "(max-width: 918px) 100vw, 918px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6b31e598b96b1d508e0e741737b8ed1e/81c8e/Homee_Adding_Sensors_07.png 230w", "/en/static/6b31e598b96b1d508e0e741737b8ed1e/08a84/Homee_Adding_Sensors_07.png 460w", "/en/static/6b31e598b96b1d508e0e741737b8ed1e/e0885/Homee_Adding_Sensors_07.png 918w"],
              "sizes": "(max-width: 918px) 100vw, 918px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6b31e598b96b1d508e0e741737b8ed1e/e0885/Homee_Adding_Sensors_07.png",
              "alt": "Homee INSTAR Cameras",
              "title": "Homee INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now I repeat the previous steps with the `}<a parentName="p" {...{
        "href": "https://hom.ee/products/devices/abusz-waveinnensirene-001-siren"
      }}>{`ABUS Indoor Siren`}</a>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "915px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5e89ec90743ac83e0d04ce1a0463229b/4255a/Homee_Adding_Sensors_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.826086956521735%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAA7CAAAOwgEVKEqAAAACeElEQVQoz4WRSU9TURiG7+9hByhTW29Vhp7T297SMomUSASNxhgSI4kaicaBtAhuDJi4MMoQTBRYuCGsHMJQEUpDigYQhJZCmQRCZPeYeyGoEXXx5LyL8z353nyKrG/DwN/4kr53IaITE4xHIoRCIcbCY4yHI4yMDdI1e4buxXO8itXQMV/8B+1f91C0hh7k/R6Km14zEPnMYuIL0clJwuEw0WiU8fA4C7F5WrrqOXtVcvGml5pr8neuOzl/QzNRLjx9z+XnA+jN/UTmFpj+1k88EWNxMcHy8jLxeJzd77sEG5rJPGrB4yrCp5dS6C7Bq5fidZegaz7yTkjyjguUO90j3Ov9iPdhH+HpGNubG6ytrR2wurrKzs4OD5oa0VxO/P4KKiv9+CsrzGy8Ff7TlJWVonvcKBWP31Le+sZkdCrG5voayZUVEokES0tLJJNJtre3CQQC2O12PB4PHl1H13Uz6/vZ6XQipURx3WrDQL/dzuinWTY31k3hyj6/ClVVNQeFFDgcgoICh5mFcCCEMFFUux2L1YbVdsy87NbWlikxqh4m1Fwa+bkCzSVxe6SZhZDmdlJIlMyMDI6kp5OWmsrQ0NCB8LAN7XYVke+kus5FS8jLk4iPKw91HPliT2hUzsrKJi0tjZSUFAYHB83hQzcMBrDZVLylLlPUFS+jY+4UHQvFVNVq5J0USKdEycnJwWq1mgwPD/+1cjAYwJqjUl7t5tmMj44ZP51TVXTGi7h0102uKnBqEsVms2GxWEz+Jfx5FI2G3kJeJIvoWi6i9YMPb5nEUbBXWzE+ZWdnY2z6X6FdRQoNl0dSG3RT90in2L93GKOuceUft9qHmufaEJUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5e89ec90743ac83e0d04ce1a0463229b/e4706/Homee_Adding_Sensors_08.avif 230w", "/en/static/5e89ec90743ac83e0d04ce1a0463229b/d1af7/Homee_Adding_Sensors_08.avif 460w", "/en/static/5e89ec90743ac83e0d04ce1a0463229b/6e770/Homee_Adding_Sensors_08.avif 915w"],
              "sizes": "(max-width: 915px) 100vw, 915px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5e89ec90743ac83e0d04ce1a0463229b/a0b58/Homee_Adding_Sensors_08.webp 230w", "/en/static/5e89ec90743ac83e0d04ce1a0463229b/bc10c/Homee_Adding_Sensors_08.webp 460w", "/en/static/5e89ec90743ac83e0d04ce1a0463229b/632b0/Homee_Adding_Sensors_08.webp 915w"],
              "sizes": "(max-width: 915px) 100vw, 915px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5e89ec90743ac83e0d04ce1a0463229b/81c8e/Homee_Adding_Sensors_08.png 230w", "/en/static/5e89ec90743ac83e0d04ce1a0463229b/08a84/Homee_Adding_Sensors_08.png 460w", "/en/static/5e89ec90743ac83e0d04ce1a0463229b/4255a/Homee_Adding_Sensors_08.png 915w"],
              "sizes": "(max-width: 915px) 100vw, 915px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5e89ec90743ac83e0d04ce1a0463229b/4255a/Homee_Adding_Sensors_08.png",
              "alt": "Homee INSTAR Cameras",
              "title": "Homee INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Both Z-Wave devices are now paired with the `}<strong parentName="p">{`homee`}</strong>{` gateway and can now be used logical IF-THEN chains that `}<strong parentName="p">{`homee`}</strong>{` calls `}<strong parentName="p">{`Homeegrams`}</strong></p>
    <h2 {...{
      "id": "adding-zigbee-components",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#adding-zigbee-components",
        "aria-label": "adding zigbee components permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Adding Zigbee components`}</h2>
    <p>{`For the Zigbee example, this time we log in with the `}<a parentName="p" {...{
        "href": "https://itunes.apple.com/ro/app/homee/id611086136?mt=8&ign-mpt=uo%3D4"
      }}>{`homee iOS app`}</a>{` or `}<a parentName="p" {...{
        "href": "https://play.google.com/store/apps/details?id=com.codeatelier.homee.smartphone&hl=en"
      }}>{`Android app`}</a>{`. Switch to the `}<strong parentName="p">{`Devices`}</strong>{` tab and click on the large `}<strong parentName="p">{`+`}</strong>{` to add a new device. Then select `}<strong parentName="p">{`Zigbee`}</strong>{` here:`}</p>
    <p><img parentName="p" {...{
        "src": "./Homee_Adding_Sensors_09.png",
        "alt": "Homee INSTAR Cameras"
      }}></img></p>
    <p>{`In the following list you can find all supported Zigbee devices. Select your device - in the example it is an `}<strong parentName="p">{`Osram LIGHTIFY Plug`}</strong>{` socket. In the following window, press `}<strong parentName="p">{`Start`}</strong>{` and then press and hold the power button of the socket for 10 seconds to put it into pairing mode.`}</p>
    <p><img parentName="p" {...{
        "src": "./Homee_Adding_Sensors_10.png",
        "alt": "Homee INSTAR Cameras"
      }}></img></p>
    <p>{`The device should then be integrated and you can switch the socket on and off via the homee app:`}</p>
    <p><img parentName="p" {...{
        "src": "./Homee_Adding_Sensors_11.png",
        "alt": "Homee INSTAR Cameras"
      }}></img></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "camera-alarmserver-and-homee-webhook",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#camera-alarmserver-and-homee-webhook",
        "aria-label": "camera alarmserver and homee webhook permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Camera Alarmserver and homee Webhook`}</h2>
    <p>{`In this step I want to generate a web hook - a URL on the `}<strong parentName="p">{`homee`}</strong>{` gateway that can be accessed by the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Alarm/Alarm_Server/"
      }}>{`Alarm Server`}</a>{` on an INSTAR Full HD camera. This web hook is then contacted in case of an alarm on the camera and should trigger the ASUS siren I installed earlier.`}</p>
    <p>{`Start by going to the `}<strong parentName="p">{`Homeegrams`}</strong>{` tab and click the `}<strong parentName="p">{`+`}</strong>{` button to add a new function:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "918px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/55b5b50bae9308bd9a38544855e7f1cc/e0885/Homee_Webhook_IFTTT_Siren_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.95652173913044%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7CAAAOwgEVKEqAAAACAklEQVQoz5XQ/0sTcRzH8fuPhGo4PadzoLv75Dw16IeYkBK0Sf2Q6fCnoK8/BIH9khLCIqjQhmn9IDrNxNSfQlNZ5Ca77eZwd7ubkL8/Y6dUUpH98OLF+4f3gzdvSXuQQNx9jXJngujIDOtbKXYzaVKpL2xvbvE1n2c3Hic3OsHqmx2WEtskEymSLzZJxj+RfL5xIlLX8BxXxpYJjyzS8/QDe6UyBxUH23Go2Db24TcOVtYobHzm1uwyQ5OzDEzOEZteYDC5yuD0AgNT8wxOzbstdT6a4eG7dWIv1+gZfU++WMIplzEtC8uyMEsm9uEhRrFI7Po1bkSu0t8XYagvyvjlHmLRCP3RCDePI116skjv2EfCo0v0PVshVwXtsouVj9GK46Bns7RrGkFFQRECoagMeWWEqhJUBcpxpI7742j3xhG3X9H7+C354v5PsHzUlUoFXdfRNA1FURBCoApBINTmtvglUpO/GX9zgFpvHR2dXe6i4zj/BKs5r6onMBeUfT4aGn2c83gIae0/QNM0j35omn8F/xSppV6mRZbxnTnLxbYQeV13gep1tm27/V9gd30DiiwT9NYRbg5Q2kmzb1kUDINCoYBhGC56avBCrZdWj4dATQ3dLa3sZXbJGXnS6TSZTMbtKpzNZk8HNjU2EvD7qfV46NA09Fzutx9W59Ne+B0fCEy2S5XEzwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/55b5b50bae9308bd9a38544855e7f1cc/e4706/Homee_Webhook_IFTTT_Siren_01.avif 230w", "/en/static/55b5b50bae9308bd9a38544855e7f1cc/d1af7/Homee_Webhook_IFTTT_Siren_01.avif 460w", "/en/static/55b5b50bae9308bd9a38544855e7f1cc/6d4a2/Homee_Webhook_IFTTT_Siren_01.avif 918w"],
              "sizes": "(max-width: 918px) 100vw, 918px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/55b5b50bae9308bd9a38544855e7f1cc/a0b58/Homee_Webhook_IFTTT_Siren_01.webp 230w", "/en/static/55b5b50bae9308bd9a38544855e7f1cc/bc10c/Homee_Webhook_IFTTT_Siren_01.webp 460w", "/en/static/55b5b50bae9308bd9a38544855e7f1cc/56bc7/Homee_Webhook_IFTTT_Siren_01.webp 918w"],
              "sizes": "(max-width: 918px) 100vw, 918px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/55b5b50bae9308bd9a38544855e7f1cc/81c8e/Homee_Webhook_IFTTT_Siren_01.png 230w", "/en/static/55b5b50bae9308bd9a38544855e7f1cc/08a84/Homee_Webhook_IFTTT_Siren_01.png 460w", "/en/static/55b5b50bae9308bd9a38544855e7f1cc/e0885/Homee_Webhook_IFTTT_Siren_01.png 918w"],
              "sizes": "(max-width: 918px) 100vw, 918px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/55b5b50bae9308bd9a38544855e7f1cc/e0885/Homee_Webhook_IFTTT_Siren_01.png",
              "alt": "Homee INSTAR Cameras",
              "title": "Homee INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`I am choosing the `}<strong parentName="p">{`Webhook`}</strong>{` as the `}<strong parentName="p">{`WHEN Condition`}</strong>{` for the script:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9109c13fb9fa7d2c6032a45f622e8d8d/0f67e/Homee_Webhook_IFTTT_Siren_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "76.08695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAA7CAAAOwgEVKEqAAAACiUlEQVQ4y32STW8SURiF5zcZycDADIUmph+mtlAK8wnIAIkxbdomdkc0xqoYrdWYLtyoTVxp242NicbEjxAjViMLFqbCYAeQAgN/oDnm3gJWO3Vx8iYzuc89556XGc88wmhmHWOZdcTvbODDxzwKhW/I5/PI5XL4urODnWIBxtM3wNIWDq68wMHi8xPFBLObGL++hdFrm1BWt1H8/gN7Pysol8solUowymVUzD1UvhSxmlxC9vwibqpzuKHM/i15Fre0eTBTKy9x8XGOKnTvNcxGC92OhXbbgmWR2Ua300G1UYcU1xDWZASkMCZCAUzMBP8oFEBAmgEzvbKNqxufcfnZJ4j3X6FSa8Bqt7DfbKLZE4FWzSpSehJRVYOmqJAlCYokDySLEv3HiGtvEV57j+CDd9Ae5lCp7VMgAbVah5M4rVar0HUdsVgMiqJAFMVjUlUVzNmFLMYWbmNkLoupS3dRMWuwrLYtMJFIIBqNUmAkEoEkSceBPMfCwznBnj6FIcFDy+h0OoO4R4HJZJI6TKfTti7JN4bnPRAEL1wcB//wsC2QvmEvMnGhadoxdwOHfv8QnE4nHA4HeJ6nwG63S+MSEJnkgj4wHo9DlmUa2RYYDEyCZZ3wuN0UvLu7i0bjF8qGAcMw6AX1en0QmbgjB+1KoZF9Q164XBx16PV66TKTw2T2l9s0Tap+y0QiKcXOocC7wfMCdef3+20jH10b0jJxGlEVBEl0SRqAKdAr8BAEAS6XEz6f77+l9NdGT6VwITSD+XOTUMlb9gqiQI+HA+d2g2XZgcOT1oaWEo1B03UsnxnBE05AanoaYQKMRA7fkADJytgB/11sEjUai9GVCckyplSVwo5G/g14UyKFK3W4ewAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9109c13fb9fa7d2c6032a45f622e8d8d/e4706/Homee_Webhook_IFTTT_Siren_02.avif 230w", "/en/static/9109c13fb9fa7d2c6032a45f622e8d8d/d1af7/Homee_Webhook_IFTTT_Siren_02.avif 460w", "/en/static/9109c13fb9fa7d2c6032a45f622e8d8d/7f308/Homee_Webhook_IFTTT_Siren_02.avif 920w", "/en/static/9109c13fb9fa7d2c6032a45f622e8d8d/10701/Homee_Webhook_IFTTT_Siren_02.avif 921w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9109c13fb9fa7d2c6032a45f622e8d8d/a0b58/Homee_Webhook_IFTTT_Siren_02.webp 230w", "/en/static/9109c13fb9fa7d2c6032a45f622e8d8d/bc10c/Homee_Webhook_IFTTT_Siren_02.webp 460w", "/en/static/9109c13fb9fa7d2c6032a45f622e8d8d/966d8/Homee_Webhook_IFTTT_Siren_02.webp 920w", "/en/static/9109c13fb9fa7d2c6032a45f622e8d8d/cd6b8/Homee_Webhook_IFTTT_Siren_02.webp 921w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9109c13fb9fa7d2c6032a45f622e8d8d/81c8e/Homee_Webhook_IFTTT_Siren_02.png 230w", "/en/static/9109c13fb9fa7d2c6032a45f622e8d8d/08a84/Homee_Webhook_IFTTT_Siren_02.png 460w", "/en/static/9109c13fb9fa7d2c6032a45f622e8d8d/c0255/Homee_Webhook_IFTTT_Siren_02.png 920w", "/en/static/9109c13fb9fa7d2c6032a45f622e8d8d/0f67e/Homee_Webhook_IFTTT_Siren_02.png 921w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9109c13fb9fa7d2c6032a45f622e8d8d/c0255/Homee_Webhook_IFTTT_Siren_02.png",
              "alt": "Homee INSTAR Cameras",
              "title": "Homee INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/045d873780b5a58f9cfd697214026b91/c0255/Homee_Webhook_IFTTT_Siren_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "89.13043478260869%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAA7DAAAOwwHHb6hkAAADTklEQVQ4y22R20/bVgCH/d/wAmyWeODihq2jgUBgoSBhO3bsxE7aMqGIZpCuGou6qcBW7fLSl3XSqgTUra320i3tNolKaDSRw8qglz20FCdxfDu+pXES8pTJiZjo5dOno/PgT78jGRpNrI0l1txLqfgP6d293SdPn+zs7Gxvbz/ee/z3v49KyY1G/Hbl4m17/qdq7OfXhCa/uTv97b2xL39dWP/LKEjCi/zz/X3HZ88My3yUe/jFxxe/Tly+vPjppfMXPo99clxo8rs/Vu/8w1zbjK0/sA1TU9SSWJIlSZbleq3ObeeoUDBy7ixJUyQdeE0IvXp/9voD9Or9+K2cnVfAgVAQS4IgAAAajUYmk/F6vRNvMu4ITSSue5d+HLpwLfb9HRvouqxIigwA0HW9Vqtls1nny/HxibcBIQN9gycQ+J2uEBOsCEDNl4qiIMuyaZr1ep3jOAzDCILwvwGO4xCCIC6XC4ZhlmVtq6yrQJQkwzDayxzHURTFsmy4RSQSCYfDLMsyDEPTNNTX1+dyubq7unDCb6uGWhTzhYJhGOVyudFocByHoiiO4wRBkEe0Z53l3t5eBEE6OzsxHKuaZSDJfJ4HAPwf0zTt9/uxI0iSpCgqGAwGAgHoRAv4XZgNs7ZZ1mTVebauW5Z1eHjIcVwAwymCbE9hGNY+2xcnHhwchGGYCbN2CSi8wOf5YrHI87xlWblcDp2ZmZ6ePj3lgKLozBEoikL9/f3OciuuSJouKaoGDF3XNK29TJLk8spyNpvZ29u9eevmysrylStfra6uxONxJ0YQxIkj4eq+pDzPv8gfFAsFnuer1Wr7V83NReeji9G5GBVgA0QoQDIMw9JB+ljMsi8lTZdVoGu6rgMAavV6JpMZHvb4pkaYj0ZDs6Nnor4zUd/ZeZ/vtGdk2HMsZhjTMjVdU2RFFEVFUSqVl1tbW0NDntnzUzc2mNTvofU/I8l79I2NUOjcpNs99mpsmqCFpmmqqlYqla3NTff773lOfeD1OH7odbcvnlMnR4ZOOvHAwMBbYgCqpvHb0wMqlY6spdmkYyR1l02mmWQ6mEqH1tJQd3d3T09PR0cHhmGvxJpW1dRfSvZCqXlJbH7WMtE6l8TmgtBcFJr/ASgBbEzkbYEIAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/045d873780b5a58f9cfd697214026b91/e4706/Homee_Webhook_IFTTT_Siren_03.avif 230w", "/en/static/045d873780b5a58f9cfd697214026b91/d1af7/Homee_Webhook_IFTTT_Siren_03.avif 460w", "/en/static/045d873780b5a58f9cfd697214026b91/7f308/Homee_Webhook_IFTTT_Siren_03.avif 920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/045d873780b5a58f9cfd697214026b91/a0b58/Homee_Webhook_IFTTT_Siren_03.webp 230w", "/en/static/045d873780b5a58f9cfd697214026b91/bc10c/Homee_Webhook_IFTTT_Siren_03.webp 460w", "/en/static/045d873780b5a58f9cfd697214026b91/966d8/Homee_Webhook_IFTTT_Siren_03.webp 920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/045d873780b5a58f9cfd697214026b91/81c8e/Homee_Webhook_IFTTT_Siren_03.png 230w", "/en/static/045d873780b5a58f9cfd697214026b91/08a84/Homee_Webhook_IFTTT_Siren_03.png 460w", "/en/static/045d873780b5a58f9cfd697214026b91/c0255/Homee_Webhook_IFTTT_Siren_03.png 920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/045d873780b5a58f9cfd697214026b91/c0255/Homee_Webhook_IFTTT_Siren_03.png",
              "alt": "Homee INSTAR Cameras",
              "title": "Homee INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can name your web hook and copy the complicated looking URL at the bottom - it should look something like this:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`https://yourhomeeid.hom.ee/api/v2/webhook_trigger?webhooks_key=yourwebhookkey&event=OfficeCam_LocalWebhook`}</code></pre></div>
    <p>{`This is the path for your `}<strong parentName="p">{`homee`}</strong>{` web hook - we can later test it by copying it into the address bar of our web browser and loading it. Whatever we will define in the next step as the `}<strong parentName="p">{`THEN Condition`}</strong>{` of our script will be triggered by this URL.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "917px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0825317a2c69fc733a5ed50dd7158e4b/59000/Homee_Webhook_IFTTT_Siren_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "51.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAA7CAAAOwgEVKEqAAAACUElEQVQoz2WS3U+SYRiH37/IzYabDYm2Ugg0SkWlloGZpDm31MVUIFdkZ7mKD7/Kjzopy7bQlp34mbM8clMkEFQQxscLrL/gau9rmq2Da79nz8G133Pfj2B4MoPO9R7to2nah+fZ2vlJNBIhGAwSCAQIh8Ps7kWIrm6x9DHIvD+E/1OI2Q87zL7d/su7AHPTAYQrg19pm/jGde8CLS+XSYs58nmJPIVCgVwux69CgXAiQfOLMay+SW55JzG7X2HxTmD2jMt54/kopsEhhKtPvzD4eZOuN2tYRxdJpjOIYpZMNks2myWTycjicChE0zUTrRYzreZGLA11WBrqZZpM9dysM9JorEUw+ZZoHFmh3rNIx+t1kuksOVGUZeKflNru7+/zoL+fnr4+7HY7TqcTl8uFw+E4wumU74TL/VNIaO3jtDybIZnK/C8URaKpDI9XdnAtB+hZ2JbpXdw+OR8jlKlUqNXnUZSUYDQaSSQS8txOCwv5HNF4HJtvmG6Pl063l27vEPaJKe55fHSeQlAqlajV5yhRKDAaa/8RHiPmc6SjMTYcI6zf97JqG2LN5mOjy833Ljc/TiELlcoyiovPUF1TIwulmUntJLH0/HwhT3IvxuzACP5+D5uWXjbaHjI3MIbf6cbv9Jwg6HQ6WXi2tBS9Xk88HieVSnFwcEAsFpOXIbXcjURo7mjD0nobR7WBHlMdd2ydNLVbsdy1Ymk/QlCpVCgUCoqKijAYDLLw8PBQFh5nOp2WP3qVvhJNRQUXKqu4qNOjKS9Hq9FwSaM94TdyukIemhw2ZwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0825317a2c69fc733a5ed50dd7158e4b/e4706/Homee_Webhook_IFTTT_Siren_04.avif 230w", "/en/static/0825317a2c69fc733a5ed50dd7158e4b/d1af7/Homee_Webhook_IFTTT_Siren_04.avif 460w", "/en/static/0825317a2c69fc733a5ed50dd7158e4b/bea5c/Homee_Webhook_IFTTT_Siren_04.avif 917w"],
              "sizes": "(max-width: 917px) 100vw, 917px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0825317a2c69fc733a5ed50dd7158e4b/a0b58/Homee_Webhook_IFTTT_Siren_04.webp 230w", "/en/static/0825317a2c69fc733a5ed50dd7158e4b/bc10c/Homee_Webhook_IFTTT_Siren_04.webp 460w", "/en/static/0825317a2c69fc733a5ed50dd7158e4b/f91b9/Homee_Webhook_IFTTT_Siren_04.webp 917w"],
              "sizes": "(max-width: 917px) 100vw, 917px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0825317a2c69fc733a5ed50dd7158e4b/81c8e/Homee_Webhook_IFTTT_Siren_04.png 230w", "/en/static/0825317a2c69fc733a5ed50dd7158e4b/08a84/Homee_Webhook_IFTTT_Siren_04.png 460w", "/en/static/0825317a2c69fc733a5ed50dd7158e4b/59000/Homee_Webhook_IFTTT_Siren_04.png 917w"],
              "sizes": "(max-width: 917px) 100vw, 917px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0825317a2c69fc733a5ed50dd7158e4b/59000/Homee_Webhook_IFTTT_Siren_04.png",
              "alt": "Homee INSTAR Cameras",
              "title": "Homee INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`After saving those settings, we can now click on `}<strong parentName="p">{`THEN`}</strong>{` to select our ABUS siren as the action we want to be triggered.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "916px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2b06bf2217232a1abbbbcb7dbec7f43f/59822/Homee_Webhook_IFTTT_Siren_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "85.65217391304348%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAACXBIWXMAAA7CAAAOwgEVKEqAAAAC/ElEQVQ4y42S3UtbdxyHz98zmDA1BROr7kKKo9EkJ56XxMSY5CQxdNrKqpa2CfZisrIxKIXSi7YXLWUXG4V1u9gGg3ZDsC3dlg5jfLlItDY5eTte9KJXTzk/k6CbQi8evufcPL/P90Uaydzns+x9RrIPmLv9E6vPXpDL5VhdXWVlZYW/X/7Fi3yOnXu/8W7+B95mHvH2wncnIvm+/hn39ceMLP/IuTu/U9rZZe/1a3Z3dzuUzQrrz/9hMZTiYijJnB7nghb7H3NaDGn0m1+49uglybt/krr7lGqtTqNep96iVquxv79PfnMDZUJHDweRNQXPuIxX8eNVWnVcZkwdR1Ju/MrS98+YvvOEc/eeUjar1GpVqtUWpkndstjJveLLZIrl2fMspabJxA2yRoKMkSAbN7icTHFD1ZHUW39g4735hPMPn7eENSET1TRpWBbFV/8eCKbTXEkkWYzGOlyKxrhoGHylaEhnklcZSWUYmlokdvVbyhXzqLBaxWpa5AsFQuEwk5EIRiKBHgigqCqqpqFoGqqq4td1JOepHk73neKTjz9CkT2Uy+WOqE3TslhfX2dyclKg6zoBm0DgKLbQ1X+awaFP6XU4xKuHhe3abDaFMBKJEIvFmJiYENLjkAYGBnC5XHR3dyPLshA2Go0jwv1DCW2pzYnC4eFhnC4XDoeD0dFR9vb2KJVKbG1tsb29zebmpniknXBqagrDMESLxwrtdE6nk66uLhRFoVx+I465WCoJcbFYpFKpdBLG43Gi0ejBHP87w0AAqb+/H7vt3t5eNE3rtGy3e+Sw83k8Ho/oYmxsDLfbfSxCODg4JIRqS3h4y6ZpYlmWEHq93hNF7rNnD4R9fX1iKT09PZ2ExwnX1tY6c/P7/fh8PrHEdvXLsnhQsud3krBz2K2E9rmEw2FCoRDBYFD829jf48EgflX9MKF9hxsbG6TTaebn58lmsywsLDAzM8PM7Cyfp9M8dg5y3eP7cGGhUBAn075DO6mdTCQNBvnC4yWpqLwHlrWKcMEztX8AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2b06bf2217232a1abbbbcb7dbec7f43f/e4706/Homee_Webhook_IFTTT_Siren_05.avif 230w", "/en/static/2b06bf2217232a1abbbbcb7dbec7f43f/d1af7/Homee_Webhook_IFTTT_Siren_05.avif 460w", "/en/static/2b06bf2217232a1abbbbcb7dbec7f43f/1e879/Homee_Webhook_IFTTT_Siren_05.avif 916w"],
              "sizes": "(max-width: 916px) 100vw, 916px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2b06bf2217232a1abbbbcb7dbec7f43f/a0b58/Homee_Webhook_IFTTT_Siren_05.webp 230w", "/en/static/2b06bf2217232a1abbbbcb7dbec7f43f/bc10c/Homee_Webhook_IFTTT_Siren_05.webp 460w", "/en/static/2b06bf2217232a1abbbbcb7dbec7f43f/3fccf/Homee_Webhook_IFTTT_Siren_05.webp 916w"],
              "sizes": "(max-width: 916px) 100vw, 916px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2b06bf2217232a1abbbbcb7dbec7f43f/81c8e/Homee_Webhook_IFTTT_Siren_05.png 230w", "/en/static/2b06bf2217232a1abbbbcb7dbec7f43f/08a84/Homee_Webhook_IFTTT_Siren_05.png 460w", "/en/static/2b06bf2217232a1abbbbcb7dbec7f43f/59822/Homee_Webhook_IFTTT_Siren_05.png 916w"],
              "sizes": "(max-width: 916px) 100vw, 916px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2b06bf2217232a1abbbbcb7dbec7f43f/59822/Homee_Webhook_IFTTT_Siren_05.png",
              "alt": "Homee INSTAR Cameras",
              "title": "Homee INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click on `}<strong parentName="p">{`Device`}</strong>{` and pick the siren from the list below.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "916px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/aaf4ad2e78f9b531c075a88b3c095779/59822/Homee_Webhook_IFTTT_Siren_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "90.43478260869566%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAAA7CAAAOwgEVKEqAAAADTklEQVQ4y42S3U9bdRjHz99DIom8LLa8Lq5AT1+A066n9IUOUIRCYa0CRkpHDNOYuCyIiTE6jM5oduFifIlxRuMkIWFsbnbSU8IgQguU0wKtN1x48THnrKuwVd3FJ9/8bj75Pr/nEcyxq4ixq5hjnxJ5/xuWbi0Tj8dZWlpicXGRe7/e5bYSJzX/A3+NX+co9iVH4WscRcojSG9/i+2trzG/+RXDV35iM5Vme3ubdDr9kFSaXXUP5dY9Qs4Ag5Kf4H8g2C99z/T1O/RfWWBgfoFsbp+D/X32i+RyOQqFP0kmFdyd7Zy1WXBYRBwWc1kE1+wNXv9imeD8Lwx/dJOMmiWXy5LNFlFV9vN5UnfucvF8mJmxcWIjo0wODRMdDhEdCjE1NMyro6PMebsR5PcW0JDevcn5z5aLwpwu01NVOcjn2bh/nwuDQaZDI4z19hEJBHg5cK5EuKeHKXcXQutADPPgNM29r9EXmyWzp54Qqtks+XweJZmky+PBJcs4HA6kx5EkOpxOBOOpahoMp3j2mQpcjg4ymUxJqKGqqi5MJBJYrVba2toQRRHRbC6LUFffQFPzaWpqa/UGx4WPUm+oKLg9XZyVZSSnk85is85jaG+hoaGBuro6qqqq9FE04cHBwQlhoVAgmUzyUk8P/YEAfpeM3+V6Ap8sI5hMJl1YW1uL3W5jZ2eHra0tHqyvs7GxzoO1NXb29kitrHB5bJw3xieIBoNEg0MlpgaDTIRGeMfjQ9BkRqOByspKXC4Xmd1d/aA3Nzd1sZYZVeWPlRVe8fsJ+XyEPN4nCPr8THZICPX19TQ2NlJTU4Pb7S6NrI37z2EXWEkksGkff8aEaCrDGROtLS0P/7CpqUkXykXh41s+PDxkdXWVF/v76e3rozsQ+FcEg8GA1rK6urrU8LhQQxOura0RjUaZnJwkEg4TiUSIRMInCIfDCEajUV9KOeHxs9G2PDgwQKC7G4/Hi8/nK+Itpdfr/X/ho8NWEgp2SULsbKe9ox3RYsMiWrHoacNqtWG3259OWMjn+U1R6G9tIV5RwbL0HB//2MPnN17gk++6ufbzOWbmZJ4/3fr0wt8TCh6LyAeNBj50NHNhtouZy16mL7m4OCczMiHRYjLzN5+3wYBBilzaAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/aaf4ad2e78f9b531c075a88b3c095779/e4706/Homee_Webhook_IFTTT_Siren_06.avif 230w", "/en/static/aaf4ad2e78f9b531c075a88b3c095779/d1af7/Homee_Webhook_IFTTT_Siren_06.avif 460w", "/en/static/aaf4ad2e78f9b531c075a88b3c095779/1e879/Homee_Webhook_IFTTT_Siren_06.avif 916w"],
              "sizes": "(max-width: 916px) 100vw, 916px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/aaf4ad2e78f9b531c075a88b3c095779/a0b58/Homee_Webhook_IFTTT_Siren_06.webp 230w", "/en/static/aaf4ad2e78f9b531c075a88b3c095779/bc10c/Homee_Webhook_IFTTT_Siren_06.webp 460w", "/en/static/aaf4ad2e78f9b531c075a88b3c095779/3fccf/Homee_Webhook_IFTTT_Siren_06.webp 916w"],
              "sizes": "(max-width: 916px) 100vw, 916px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/aaf4ad2e78f9b531c075a88b3c095779/81c8e/Homee_Webhook_IFTTT_Siren_06.png 230w", "/en/static/aaf4ad2e78f9b531c075a88b3c095779/08a84/Homee_Webhook_IFTTT_Siren_06.png 460w", "/en/static/aaf4ad2e78f9b531c075a88b3c095779/59822/Homee_Webhook_IFTTT_Siren_06.png 916w"],
              "sizes": "(max-width: 916px) 100vw, 916px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/aaf4ad2e78f9b531c075a88b3c095779/59822/Homee_Webhook_IFTTT_Siren_06.png",
              "alt": "Homee INSTAR Cameras",
              "title": "Homee INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`When the web hook is triggered, we want the siren to switch on without a delay - click on `}<strong parentName="p">{`Done`}</strong>{` to save those changes. Then repeat those steps - but define a switch off with a short delay (`}<strong parentName="p">{`to prevent the siren from going off indefinitely!`}</strong>{`):`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`https://yourhomeeid.hom.ee/api/v2/webhook_trigger?webhooks_key=yourwebhookkey&event=OfficeCam_LocalWebhook`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "917px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9256f21e392df4a58dc2c785e79d4005/59000/Homee_Webhook_IFTTT_Siren_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64.78260869565217%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACwElEQVQoz12Q/UsTcRzH7+8JEoSrITkfCASfwEqb7vboPLPN293NlQ/Jpmtud3vSwNymRSMDQ6Eww2jVD2qMzLT0h/Bhd9udQ93d9zbn77XYFia9efPh/cvr8/nwhuptzxtss/X22b7w2/WvGzvb2+vr67FY7Mfm1sbPndSTaJ5c+GVfzI8u5a0LefNcHn9Z8u/BV9AN75smarHe9dr89FMiyR3yfLIonuMPUzyzw8xHmUg0Hn7PzLyLzywflBxePpj7yEAt4x8G5zf006umyGcp80+SJJ3lcvs8px5xom6/we3TONzah7TGQemcnrahEZNnHGqbiHqXtrDIGvZsRRCBBP5KFMXT09ODvb0ercbUqTPqdSiiRFUFd6sRneL2fdwMdYTWlKG11scrlrmN/+BsNpNIcg5vwOam7ZR31OOnHk2O0L5Rj9/moieC01CDJdBonbiOeXroiCCK4BwGICsB5kQci+07Y/sDq3sFrxXm4OrevZVd6kscuiaD5RWy8suXOtpuCem0JElicUXpMstxw/7AoNc35PMPBwJjwVAp36doOjwNyauqampr4StXEZU6LQjnMBBFkM2IDJ8YjjB9YcY8xeBBBg+yRIglQyw2lXS8gCqLKi8vVygUgiBkMhkAgCRJEgDZs7Oj79u03uTpJn0oSXdiHoOZ0vVSOpNN1RO8qYbq6uoqKytlMllLS8vx8XEqlUokEslkkmXZtAS4zc12VUe7QafqNii79Aja2aZVtaqVTVqEaG4qXK6oqCgrK1MoFCcnJxzHleAEyx4J6cNvm3cQlRHtJu4ajV1oj77T1IUa9Qa1RvugsRmSy+XV1dUwDCMIcvFtAEA2l+N3d10E6cQJF2lxkRZ//8AYQY7hhL0Xm+yzFuCa2hoYhpVKpXChsGLb2TjLEta+XhzHCMJMktb+/lIwYZjd6fwDkLzpTg+wdjwAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9256f21e392df4a58dc2c785e79d4005/e4706/Homee_Webhook_IFTTT_Siren_07.avif 230w", "/en/static/9256f21e392df4a58dc2c785e79d4005/d1af7/Homee_Webhook_IFTTT_Siren_07.avif 460w", "/en/static/9256f21e392df4a58dc2c785e79d4005/bea5c/Homee_Webhook_IFTTT_Siren_07.avif 917w"],
              "sizes": "(max-width: 917px) 100vw, 917px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9256f21e392df4a58dc2c785e79d4005/a0b58/Homee_Webhook_IFTTT_Siren_07.webp 230w", "/en/static/9256f21e392df4a58dc2c785e79d4005/bc10c/Homee_Webhook_IFTTT_Siren_07.webp 460w", "/en/static/9256f21e392df4a58dc2c785e79d4005/f91b9/Homee_Webhook_IFTTT_Siren_07.webp 917w"],
              "sizes": "(max-width: 917px) 100vw, 917px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9256f21e392df4a58dc2c785e79d4005/81c8e/Homee_Webhook_IFTTT_Siren_07.png 230w", "/en/static/9256f21e392df4a58dc2c785e79d4005/08a84/Homee_Webhook_IFTTT_Siren_07.png 460w", "/en/static/9256f21e392df4a58dc2c785e79d4005/59000/Homee_Webhook_IFTTT_Siren_07.png 917w"],
              "sizes": "(max-width: 917px) 100vw, 917px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9256f21e392df4a58dc2c785e79d4005/59000/Homee_Webhook_IFTTT_Siren_07.png",
              "alt": "Homee INSTAR Cameras",
              "title": "Homee INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`We can now add the web hook URL to the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Alarm/Alarm_Server/"
      }}>{`Alarm Server`}</a>{` settings our camera. But we are not going to use the web URL that we were given but use the `}<strong parentName="p">{`local IP of our homee base station`}</strong>{` with the `}<strong parentName="p">{`port 7681`}</strong>{` instead - e.g. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`192.168.2.49:7681`}</code>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9a8485a7f36c45c4803edbb755e460bb/c0255/Homee_Webhook_IFTTT_Siren_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "81.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAAsTAAALEwEAmpwYAAACW0lEQVQ4y32UXW/SUBjHy+bFEp0x3s1oAtPwMkCvLFnHy4BSoIW2p5QCLS1mhMD0xk/g7Wait/sSfgrinfE7/c1zunYdmbv45eGc0t95Xk4qlMtllEolnJycoFR+j9y7NCR9idXNX8x/bOH//I3x9Rb21Rb29X1GV1tMv2+xvvkD59svvH6ThkAiolgsolAoIJvNoiJ+BJv4YO4KzF2GzC5g7kD75mwJy1tBVnrY39+DEGVHQoILKyJs9wIs+AI23zyI5V+GBJ/hLL9C6Q+RSgkQSBIJKZJQFEV4rotPiwCLRQBnbIOZBkzC0Dn6cMAZairPstMbQhCEMMNIdleyiNlsBs/zMJlMMBqNYI1G0DQN7XYb9XodkiRBOjvjsVarQZZlvDo6gkCSqNxYWKnEQsYYTNOEZVlc2Gq10Gw2OdVqFafSKY+0/+Tg4P9CyoyYTqccypLEdADJCcMwYOg6vLmHYb+PDy9ehsKHeui6Lnzfx3w+55HWtm3HQnYrpVasN5fQVQ2Zw+ePC0lGZXMBYzFcxBgMi8EbmNisxzhncjiU5JXZFQZBEPcx6mGn00Gj0eB9U7pd9GQFA1VGUz7HnpC6uzb3plyp8L6RlOBTtizouo5er8cnqigKP0BV+9AsD52uhsNnT0Ph7rURb68N9Y5iVHI0jOiAEAZ3sYKmm3h7fByWTKKIpJCgl5O9o/Uui8DHYKAhncmEQ8nn85ykkEqlsqNMSEhTpj3HcTAej2NoraoqMklhMkPqIf2JMozuIAkp0j5B8ohHhfSbekqfteR+spJd6Fkul+PCf8WpPkVcxtTLAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9a8485a7f36c45c4803edbb755e460bb/e4706/Homee_Webhook_IFTTT_Siren_08.avif 230w", "/en/static/9a8485a7f36c45c4803edbb755e460bb/d1af7/Homee_Webhook_IFTTT_Siren_08.avif 460w", "/en/static/9a8485a7f36c45c4803edbb755e460bb/7f308/Homee_Webhook_IFTTT_Siren_08.avif 920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9a8485a7f36c45c4803edbb755e460bb/a0b58/Homee_Webhook_IFTTT_Siren_08.webp 230w", "/en/static/9a8485a7f36c45c4803edbb755e460bb/bc10c/Homee_Webhook_IFTTT_Siren_08.webp 460w", "/en/static/9a8485a7f36c45c4803edbb755e460bb/966d8/Homee_Webhook_IFTTT_Siren_08.webp 920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9a8485a7f36c45c4803edbb755e460bb/81c8e/Homee_Webhook_IFTTT_Siren_08.png 230w", "/en/static/9a8485a7f36c45c4803edbb755e460bb/08a84/Homee_Webhook_IFTTT_Siren_08.png 460w", "/en/static/9a8485a7f36c45c4803edbb755e460bb/c0255/Homee_Webhook_IFTTT_Siren_08.png 920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9a8485a7f36c45c4803edbb755e460bb/c0255/Homee_Webhook_IFTTT_Siren_08.png",
              "alt": "Homee INSTAR Cameras",
              "title": "Homee INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The server path is always `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/api/v2/webhook_trigger`}</code>{`, the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`webhooks_key`}</code>{` is the `}<em parentName="p">{`yourwebhookkey`}</em>{` from the webhook URL above and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`event`}</code>{` is the name you have given your webhook.`}</p>
    <p>{`When triggern an alarm on your camera you should now see an entry inside your `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/System/Log/"
      }}>{`camera log`}</a>{` that the alarm server (in this case the homee base station) was contacted. The siren should be triggered shortly afterwards.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "instar-ip-cameras-and-external-z-wave-motion-sensors",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#instar-ip-cameras-and-external-z-wave-motion-sensors",
        "aria-label": "instar ip cameras and external z wave motion sensors permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`INSTAR IP Cameras and external Z-Wave Motion Sensors`}</h2>
    <p>{`We now are able to contact the `}<strong parentName="p">{`homee`}</strong>{` gateway with our camera in case of an alert. Let's do the opposite now. Whenever the Z-Wave `}<a parentName="p" {...{
        "href": "https://hom.ee/products/devices/fibaromotionsensor-049-motion"
      }}>{`Fibaro Motion Sensor`}</a>{` is triggered I want to send an CGI command to my camera that triggers it to move to a different position. E.g. I have a camera that primarily is used to watch the entrance door. But when a person enters the room from the side entrance the motion sensor is triggered and should move into that direction while starting to record a video.`}</p>
    <p>{`Again we start with writing a `}<strong parentName="p">{`Homeegram`}</strong>{` and defining a `}<strong parentName="p">{`WHEN Condition`}</strong>{` for the script:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "887px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e51bee4abf25162e55eb99fc1c1198c8/eac55/Homee_Webhook_CGI_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.043478260869556%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7CAAAOwgEVKEqAAAACMElEQVQoz4XP60tTYRzA8fP3pFRmYlpqK3XbOQtOWe96EeirCrE2LyFSEr5MiO5BlqF5mSvKPFFIaaILV2ikhcouuXafW+luXfAb52wFRuiLLzzP78XneX7Cx45K1JY6ZWZH7vHu/RxvHQ4mJyd5Y7fjmJ3hw/QM6+YBsAxCy1C2071wsgdObUzwdxrwX6omeFliecLKsj/EZ48bl9OJJxDgy9QUzrYOXiluXto+oTxcQHm0gNI/h9I3h9I/vyHhe9cxfvSeIHO1ktT8c1LrkFhbJZ1O8xP45fMT6LPSPu6gdWSMJuU1zSPjNI3aaRy106zen038TUhb68mMXSF1vZpvjmFi3hgejwuXy8XS4iIerxdfNMrFcy20mc/Q3mimtdFMl8XC3YYG7XzBcpbzFrOWkOmtJfOgjsydGlanHxP3xgiFgoTDYSKRCLGVFZY9Ho7UHMUgihwymSiTZe7vLKJnx27KZRmTZEI0ZROSNySSN02krun5OvOUaCJNNBLWQLV4PI7b7UaWZfR6PZLRyEFJoqFCR32FjgOSpM2MuYS6wzpqZR3H9cW8eGJjLZEkGAzg8/nw+/0aqK6vggaDAVEUEY0iVZJEdQ7TZrmEopJ9qG3bXsDAoJVUMkkoFCIajWor/w80imIWUn+lPZA9a2BFeRlqhYW7GLLZSOZAFdsMFP8F//zQVLwHuXQvVfn5KN3dJFIpwluAmyWUlJayv6ycgrw8hm/dJplOb7nyZuBvSaNbJrAH+HAAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e51bee4abf25162e55eb99fc1c1198c8/e4706/Homee_Webhook_CGI_01.avif 230w", "/en/static/e51bee4abf25162e55eb99fc1c1198c8/d1af7/Homee_Webhook_CGI_01.avif 460w", "/en/static/e51bee4abf25162e55eb99fc1c1198c8/abb2d/Homee_Webhook_CGI_01.avif 887w"],
              "sizes": "(max-width: 887px) 100vw, 887px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e51bee4abf25162e55eb99fc1c1198c8/a0b58/Homee_Webhook_CGI_01.webp 230w", "/en/static/e51bee4abf25162e55eb99fc1c1198c8/bc10c/Homee_Webhook_CGI_01.webp 460w", "/en/static/e51bee4abf25162e55eb99fc1c1198c8/1938b/Homee_Webhook_CGI_01.webp 887w"],
              "sizes": "(max-width: 887px) 100vw, 887px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e51bee4abf25162e55eb99fc1c1198c8/81c8e/Homee_Webhook_CGI_01.png 230w", "/en/static/e51bee4abf25162e55eb99fc1c1198c8/08a84/Homee_Webhook_CGI_01.png 460w", "/en/static/e51bee4abf25162e55eb99fc1c1198c8/eac55/Homee_Webhook_CGI_01.png 887w"],
              "sizes": "(max-width: 887px) 100vw, 887px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e51bee4abf25162e55eb99fc1c1198c8/eac55/Homee_Webhook_CGI_01.png",
              "alt": "Homee INSTAR Cameras",
              "title": "Homee INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`This time we need to select the `}<strong parentName="p">{`Motion Sensor`}</strong>{` from the list:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "891px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f2f1b4bfb8bd11cbf948a7499c19b663/b7877/Homee_Webhook_CGI_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "89.13043478260869%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAAA7CAAAOwgEVKEqAAAADbElEQVQ4y42TW08cdRiH5xN46xfwzhsWBMOewFIOu+susLvM7hwKUk5LOXZZwF1YjEDb1ARJTCT0QtKoiU0qVWNvvOkdNcFE4k0tVIkNlD0vp6W0tPQxM5RKrWAvnrzvPzPz5P29MyPcuXia3y+Wc/dSOYsz3fwyP8/CwgI/377N3Nycfp7/7Vc2Jn+CyI/sj9xkv+8G+6F/eNI3CwM3udv2OULiMweJyQqSn5aR+LqT1ZUVYmsxVldXWVtbIx6LkcxmuHVhiuuNYb5r/4jZsxFmm4aeE+HbsxG+bxnhqq8d4dEVB3vXO3k8U8futQAP9x6T28mxubnJzs4OuVyO/WfP+OryJXo7AoRDQXo6ApzvOveCYGc7nb1dfKKqCLkrLh7dmuThN23krrWzvrTCgz//YumPe9xbWmJxcZFMNsvU2Dhdoo/htgD9DY10+SS6/QfovaxyzuVCeHJV5OmXMk9n3Ozd6GV9Lcl6KkN2PcvGxgaZTIbd3V0+Hh/HXVdHfWMjkqoiShI+SdKrhl9RcNa6EJYn7CxPOFi+fJr7M+2ksxnSmTSJeJx4PE4ymWR7e5uxsTEkSaKhvh7J70eWpJdQFBWPJiw/ZaXiVCnGd9/hgzMK6VSaVCpFLBbTSSQSL4Q+n49GbUJJegVN6K6tRjCbzZgtVgz5BUiSTDqdfonDCUdHR/UHA4HACUIXgsVswmKxYDDkIcuSLtEmPOSoUBRFmpubUVX1eGFVVSXFxUYKCwtxu9265FD67wk1YUtLix7b7/e/IqypdiKUlb1HUVEReXl5eDweUqn0sUJth62trciy/B+xZVRFRLBYzFitVgwGg37j0chHhdpL0a43NTWhKIreK7J8UBUZv1+mueUMgtVq1ndYUFBwrFD7W0ZGRqipqdGn1JJ4vV48Gs97d60bpV46EJaUlPyvcGBggNLSUhwOBza7HbvNhsNm06vdbqeysgqPWItgMhVjNJrIz88/UTg8PIzT6UT0erF7PPRU2rjz1tsEK6p43+uh1lWNpPo0oRGT6WShtsNoNKp/BYok4RRFporN8MabfFFkxCWKeN2eg8ivI9za2mJiYoKOjg6GIhGC/f2MB/v4QW3gQk8voQ8H6Qv20X2+8/WF09PThEIhPXo4HGZwaIjeaJSBoQiRSJjBwTDR4RB/A02Cu5r8KZH8AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f2f1b4bfb8bd11cbf948a7499c19b663/e4706/Homee_Webhook_CGI_02.avif 230w", "/en/static/f2f1b4bfb8bd11cbf948a7499c19b663/d1af7/Homee_Webhook_CGI_02.avif 460w", "/en/static/f2f1b4bfb8bd11cbf948a7499c19b663/8c2dc/Homee_Webhook_CGI_02.avif 891w"],
              "sizes": "(max-width: 891px) 100vw, 891px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f2f1b4bfb8bd11cbf948a7499c19b663/a0b58/Homee_Webhook_CGI_02.webp 230w", "/en/static/f2f1b4bfb8bd11cbf948a7499c19b663/bc10c/Homee_Webhook_CGI_02.webp 460w", "/en/static/f2f1b4bfb8bd11cbf948a7499c19b663/3b50c/Homee_Webhook_CGI_02.webp 891w"],
              "sizes": "(max-width: 891px) 100vw, 891px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f2f1b4bfb8bd11cbf948a7499c19b663/81c8e/Homee_Webhook_CGI_02.png 230w", "/en/static/f2f1b4bfb8bd11cbf948a7499c19b663/08a84/Homee_Webhook_CGI_02.png 460w", "/en/static/f2f1b4bfb8bd11cbf948a7499c19b663/b7877/Homee_Webhook_CGI_02.png 891w"],
              "sizes": "(max-width: 891px) 100vw, 891px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f2f1b4bfb8bd11cbf948a7499c19b663/b7877/Homee_Webhook_CGI_02.png",
              "alt": "Homee INSTAR Cameras",
              "title": "Homee INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Select `}<strong parentName="p">{`Motion Alarm`}</strong>{` and that you want to be alarmed when a motion is recorded:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "885px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/39445ad689071949cb266d9ec8660367/efc66/Homee_Webhook_CGI_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "110.43478260869566%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAACXBIWXMAAA7CAAAOwgEVKEqAAAAD3ElEQVQ4y42T609bZRyAz5+zT0M+MOhlBVHYgBYstJSWFloWbjLuF0eLDhIzMDPBOMtNOGh0wWyZRuP0k4nRhA2XZVOjOGmZIoPSlssKbaGKj3mPDNcBcyd5kve8J+9zfrdXmr3wIrMXcvilP5c5r53bN77hzt0fmLl5k+npaW7NfMfMj3cIyF9B16f83fcFdHx8JFLokp7gW6cIDeYQGjHzYP4eS4EVFhcXWVpaYiUQYGMzwpfjU7xpa+JSTTeDjlYGHW2HIm2P5LMzVU3ioxrio4XEImGiOwkikYdEo1EikQji8Y6PkZl/isIyM7lGAzkvJfNoT9oa1hO/3kf88/NEJ4rZmL3H8twCc34fvjkfs7OzhNfW+ESepKHEhLu2jg6niy5XFV1VZ+hynaHTWaXstdodSLuTJey+X8quXMzuB1YeLtxnLbTO6toq6+vrrK2usrW9zdcfXub8yw1c7O1lwO3hjZ4eBjw99O+t+91uetvakX4ayEeh/zQ/XywiuPQ74fUNVlZWWF5eVmq4FYsxNTZGrbEYT10dna5/IxIRirWgu7qGtspKpCydluysTLQaFS88n8X8/LwSmZAJAoEAsWiUcVmmrLycuoYG7JUV2Csrk3A4nZQ7HEhqjQbtSR0ZKhW6zMx9YSgUIhwOK5HGYjFGR0Y4nZuL2WSi2Gg8lBKjEUmjVqPVakk/cQKdTpckFAhhPB7nbe87aHQ68g0GcvPyjkTKy8tDpVKh1Z4kPT0dn8/HxsZGklCMz+WhYVx6A41WGzXGYqqPQMrOzlZEaWlpZGRk4Pf7/0tZCEMhomvrXB8dw93ewetuD+7WVs41t9Dd0kJ3czKSRqNRUhXSzKysgykHg0Q3N7niHaLJVo67ppazVitnyw5HEvUTiOgyn2jKfsqxGBOyTKnZjMvpxFpWdiT7EYo6HimMRpmUZWw2G06nE4s4bLNhtVoPIAmRQEn5KUJZlpUD9fX1uFwuZS1+8CSSWq1G8LSUxRwODQ2Rk5ODyWSiqKgIg8FwKM8kFHPo9XqV74WFhRQUFKB/HL1+n2eMMMr4xAQms5lKUUOLBfMepRYLFksZpXsoNfw/oeiyPP4uFlMJVRUOzGYTTouFCtEcSylWswlrqVlhX3hkU8Qcbm3y3pVr2Js7qO3po7Gpg9vHUrh77Dl6rRWYWl/B0diGvak9OeWsA4MdJhgKsxUOcnUhQrPvT9z+BN2+BKNXv2X4s1u89v0qnf4E5+bidPy6jZSSkkJqairHjx9Xrt/jV++RcDMc5NofEV6dT9D/2w4e/w7tD6BzEbrv/6W8e/wJunw7/AN+sJlifklpwQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/39445ad689071949cb266d9ec8660367/e4706/Homee_Webhook_CGI_03.avif 230w", "/en/static/39445ad689071949cb266d9ec8660367/d1af7/Homee_Webhook_CGI_03.avif 460w", "/en/static/39445ad689071949cb266d9ec8660367/01786/Homee_Webhook_CGI_03.avif 885w"],
              "sizes": "(max-width: 885px) 100vw, 885px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/39445ad689071949cb266d9ec8660367/a0b58/Homee_Webhook_CGI_03.webp 230w", "/en/static/39445ad689071949cb266d9ec8660367/bc10c/Homee_Webhook_CGI_03.webp 460w", "/en/static/39445ad689071949cb266d9ec8660367/dad35/Homee_Webhook_CGI_03.webp 885w"],
              "sizes": "(max-width: 885px) 100vw, 885px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/39445ad689071949cb266d9ec8660367/81c8e/Homee_Webhook_CGI_03.png 230w", "/en/static/39445ad689071949cb266d9ec8660367/08a84/Homee_Webhook_CGI_03.png 460w", "/en/static/39445ad689071949cb266d9ec8660367/efc66/Homee_Webhook_CGI_03.png 885w"],
              "sizes": "(max-width: 885px) 100vw, 885px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/39445ad689071949cb266d9ec8660367/efc66/Homee_Webhook_CGI_03.png",
              "alt": "Homee INSTAR Cameras",
              "title": "Homee INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Next we need to define what we want to happen when our motion sensor is tripped:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "887px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6155a9e6b6758bbe8d460a76b1c985ca/eac55/Homee_Webhook_CGI_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7CAAAOwgEVKEqAAAACmElEQVQoz2XR7UtTURzA8fsvOckoa+WypLTpkFIzlSwygyzTnFP3QNk0DSoINM18WhmV+QSFkA9pij1AmZSOUqm1zd3dO+c2tQT9xr2RJL34cDjnxZfzO0eYtsfz2b4fZ9UBnM0XeDsxxocPk4yPjzE6OsqbiddMTL3H29gPhY/YMHeDtQdKnrBx7sF/BPl2EvKtBOSb8Ujt+YgeN35JQhR9yLKMHAgQDgYYnnTTNuiiacjFnUEXDQMuGl5836JxwIXwsz6Rtc4L/Lp3hNWOMywvhwhFIgQXFwmFQiwthWBjg9Zn/aSW2zhVc5Mc+3WyrtZucbyyhjRLJcJyQwo/h26x0pFH5GE+Qec83nkXs3OzzM3O4nQ6WQwGcbS0kG5IoSAvj7Mnc1Wns7PJzTy2KSc9DWG9NZ319kzWm1NZe1pA8IebgCgjSX4CgQB+v5+VlRX6ursoKjiHxVSK2ViC1WTEbrNiKS3ZpJwJX2sPofhStY/5pjz8/gUkWca3sIDX68UnikQWZbo+faN8ZAbrKyelI4oZjMPT6vovIUGn5WD8XrQ7tnEi6xhejwdZktWYx+NhwedjObTE45evuFh/F1OLg8KmNoqbHZTdf8TFprYthB07Y9m1W0tUdDRp6RlqRPldZVRJkvCLIsFImHdPnvO48Cq9JbX0FFXTd6mGftMNeouq6S26tkmIjY1lj1aLRqMhIyNDvdnfoEoUkcNLzPWOMWlpZcrazkdzq+qdzcGkuW1zrxB0Oh1KNCYmBoPBgNvt3hIUlTcMR+js6+G8qZiyShvGy2bKK204zOVU2CowXrFQevkPIS4uTo1poqLU4L8jK3w+H6urq9TX1RGv05GaYiBZn0zqYT2Nmu0cTUxCr08mRa9X/QbuN4XFBtWZ9AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6155a9e6b6758bbe8d460a76b1c985ca/e4706/Homee_Webhook_CGI_04.avif 230w", "/en/static/6155a9e6b6758bbe8d460a76b1c985ca/d1af7/Homee_Webhook_CGI_04.avif 460w", "/en/static/6155a9e6b6758bbe8d460a76b1c985ca/abb2d/Homee_Webhook_CGI_04.avif 887w"],
              "sizes": "(max-width: 887px) 100vw, 887px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6155a9e6b6758bbe8d460a76b1c985ca/a0b58/Homee_Webhook_CGI_04.webp 230w", "/en/static/6155a9e6b6758bbe8d460a76b1c985ca/bc10c/Homee_Webhook_CGI_04.webp 460w", "/en/static/6155a9e6b6758bbe8d460a76b1c985ca/1938b/Homee_Webhook_CGI_04.webp 887w"],
              "sizes": "(max-width: 887px) 100vw, 887px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6155a9e6b6758bbe8d460a76b1c985ca/81c8e/Homee_Webhook_CGI_04.png 230w", "/en/static/6155a9e6b6758bbe8d460a76b1c985ca/08a84/Homee_Webhook_CGI_04.png 460w", "/en/static/6155a9e6b6758bbe8d460a76b1c985ca/eac55/Homee_Webhook_CGI_04.png 887w"],
              "sizes": "(max-width: 887px) 100vw, 887px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6155a9e6b6758bbe8d460a76b1c985ca/eac55/Homee_Webhook_CGI_04.png",
              "alt": "Homee INSTAR Cameras",
              "title": "Homee INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Here you need to choose the `}<strong parentName="p">{`Webhook`}</strong>{` and use the CGI command as `}<strong parentName="p">{`URL`}</strong>{` that sends your camera to the stored position 1:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`192.168.2.116/param.cgi?cmd=preset&-act=goto&-number=1&-usr=admin&-pwd=instar`}</code></pre></div>
    <p>{`Here `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`192.168.2.116`}</code>{` is the IP address of the camera, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`admin`}</code>{` is the user name for the administrator account on the camera and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`instar`}</code>{` the corresponding password - change those accordingly.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "885px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3ebd3cc7fdbc90b73bb933bc46506396/efc66/Homee_Webhook_CGI_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "91.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAAA7CAAAOwgEVKEqAAAADCklEQVQ4y42T3U9ScRjHz3/U1piCgJAizsp8ARGQdzPFFzZCWdnqMu9r07hJIHVzlnPeaV104RKdOZteIGrJUnlR5BAIiqtve34TFw2dZ/vs2c45v8/5Pr/z/LjgSwWCQzXYHFJiZ9iIr18+Y+3bOpYCASwsLGA5sITljTXsjX5C3v0B2RczyDonkXtcGo4frgf/ug78KyV4TzMSeztIptLg+STS6TTjN/5g2jsBR7MJg9ZeuA2dcGnb4dI9LEbbDu50pA5n7x3ITzuR89xH5mgffDqDaDSCWDyOSCSC8/NzjPp9UNythUqnQaNGVQI1mlrV4DJvHiA78wTZaTcyb9Xg1zcQC/3Ej/Audnd3sb29jVQqhYnxcei0Oti77LB3dqHrAvsFXY86YbNYwcHbDPjUYPWdFqmD70jyv5BMHoPneSQSCeTzefh8PjQ2NsJms8FisVxiNptZNRqN0Ov14OYHazH/rBbzT2vw8fk9HIS3kThOIhqJYH9/n7WczWaZsKmpCW1tbdBqtQydTgeDwcDuUSW42+ViCERS3BIIWd0MhViyWCzGiEajTOj3+6FWq9kiSkKyNr0eVpOFYTNbYDYawUklYshllZCIK3BHLsPWVghJnsfh4SGOjo6YtJCQRCaT6TId8W9SllAikaCiogJlZWUQi8UIXSSMx+NMWhB6vV5oNBp0dHRcLi5AH7FarWwfuYaGBghFIkilUiYNBoMlhZSQWqZFVFUq1SUtLS1obW1lcHK5nIkEAgFEItGVCcfGxuBwOOB2uzEwMFBEf38/u9/T0wOO2pTJZBAKhawWhCQjSEynZXZ2FuFwmMnPzs6KyOVyoIuOKttDapfSVVZWlhRmMhlMTU1hbm4OKysrCAQCRSwuLmJ1dZVNAkt4nZBaPj09xcjICMrLy6FUKlFdXV2EQqFglZ5xJCLo5euEHo8HVVVVbLjr6+uvhKORIWgPrxKenJxgcnISvb29cDqdsNvt6O7uLsmNhbSH9BddLhf6+vrYH6f6PzcSFgab5o9OBc0bDXkp/gLXEtBBQMmvGQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3ebd3cc7fdbc90b73bb933bc46506396/e4706/Homee_Webhook_CGI_05.avif 230w", "/en/static/3ebd3cc7fdbc90b73bb933bc46506396/d1af7/Homee_Webhook_CGI_05.avif 460w", "/en/static/3ebd3cc7fdbc90b73bb933bc46506396/01786/Homee_Webhook_CGI_05.avif 885w"],
              "sizes": "(max-width: 885px) 100vw, 885px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3ebd3cc7fdbc90b73bb933bc46506396/a0b58/Homee_Webhook_CGI_05.webp 230w", "/en/static/3ebd3cc7fdbc90b73bb933bc46506396/bc10c/Homee_Webhook_CGI_05.webp 460w", "/en/static/3ebd3cc7fdbc90b73bb933bc46506396/dad35/Homee_Webhook_CGI_05.webp 885w"],
              "sizes": "(max-width: 885px) 100vw, 885px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3ebd3cc7fdbc90b73bb933bc46506396/81c8e/Homee_Webhook_CGI_05.png 230w", "/en/static/3ebd3cc7fdbc90b73bb933bc46506396/08a84/Homee_Webhook_CGI_05.png 460w", "/en/static/3ebd3cc7fdbc90b73bb933bc46506396/efc66/Homee_Webhook_CGI_05.png 885w"],
              "sizes": "(max-width: 885px) 100vw, 885px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3ebd3cc7fdbc90b73bb933bc46506396/efc66/Homee_Webhook_CGI_05.png",
              "alt": "Homee INSTAR Cameras",
              "title": "Homee INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`I refined the script a little bit by adding two further web hooks that are triggered with a delay of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`5s`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`15s`}</code>{`. The first one triggers an alert on my camera that in turn triggers all active `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Alarm/Actions/"
      }}>{`Alarm Actions`}</a>{`. The second one makes it return to the original position once the entering persion was recorded:Alarm/Actions/) auslöst. Die zweite bewirkt, dass die ursprüngliche Position wiederhergestellt wird, sobald die Alarmaufnahme beendet wurde:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`192.168.2.116/param.cgi?cmd=pushhostalarm&-usr=admin&-pwd=instar

192.168.2.116/param.cgi?cmd=preset&-act=goto&-number=0&-usr=admin&-pwd=instar`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "887px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2ed2689dfaaaf3983604dd8477024570/eac55/Homee_Webhook_CGI_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "83.04347826086956%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAACXBIWXMAAA7CAAAOwgEVKEqAAAADyUlEQVQ4y4WS7U9TZxiHz3+0SYGFd4aymSkBXAt9WekrlE5nBlNWBjheFMk0QVEWdRmbikoBWSLMqXPzw4iyMAWzhGwiE6RIz2kLaHtajh1eyzmgzrhkT3Ll9/t05X7u5xGmDmxh6kAhfxws5M+vdzF+a5SJybvcunmT0dFRxsd+Zez3CcRTV+CjC9AwBI3fQU0ffNgLOzfY1ctazVkEqWsbYue7SJ2FiKc9BGZnWFwMEgjMs7CwoPVQKMjN8Tku/TDL4NVZ/Ffn8F9+gH/kL/wjM/RdnmVwaIq7VU0IysntPO3zoPToSZyvRFmNkVAUZFlGURRWFQX1nL3xC4aOo7i6e7B1fYX16Ekquk5pWFWOnMDd0IYgnyhi9cYR4v6dxPs8JBdEYqEVxJCEJEralGvPnuE/14uhaDu7qyrxOux47TbcFjMOYzkOo1FLs7EcIXnaSPKcjeQ3ZSQv7ia5tIQSV0gkEjxV1lM9V0aG+dhbTdOne2ncU8u+uj3sb2rQ+nOa9tYiPDqyDY3DWwie8fDkwTyhQJD7M/eZnp7m3vR9lFiU4ak5Prt2h89/vkv99cl1fpx82TcQit/Jo3Tr22zNSafKaUacvMfyfJBgSCQUCiFKEololKGx36j99gL1F4ao6R3kk/ND+AaGtV7be/EFwlsZWWRm5/JGig59WTlPllZYTSRYWVlheXkZORbTrjzx/U+cqWlhwNdBf107g74Ohvd1MlDXzkDdwY1sR8jOyiI/Lw9dSgpmq4XY9COU8BOkSJilSIR4TNaEQf91xu1tTFR9wR3nQW472xm37+e2o/0VhIKCAjIzM0lPT6e4uBgpHEKOyzx+/JhoNMrS8jJryb/p+7Kb90qKsFVXYnbZsLjtlNs/oNiop9ho0LLEZEDYvHmzJkvV6Sgq2s7DuYdEwmHtuwTm51lcXCQuy/Qf60ZfUorb5cJpd+ByOLFZK7CYzFjMLxGysrLIzs5m06Y3MRgMBAIBIpEIkiStP4ooEo/H6T/ejX7HDrxeL263G4fDgd1u17KiogKTyYRZFaqy3NxcUlNTXxGqsnA4vC5UJzzezfulpTicztdEz9GEOTk55Ofnk5aW9prwxYSyjH9DWO314nK7cbpc2Oz2dWw2TGazhpCRkaE9ik6ne33Cfwn7u46hLymhUpU51B06qLBasZhMGsayMozl5QiqTN3jf11ZRRJFYrLMSE8PNSYzjZ5qGiqraKzy0OSpxudyUe9yU+92a/n/QkkiGotxaXiYep+PttZWWpqbtTx86JDWW1taaFWzuYV/AIhCu7vXb7BZAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2ed2689dfaaaf3983604dd8477024570/e4706/Homee_Webhook_CGI_06.avif 230w", "/en/static/2ed2689dfaaaf3983604dd8477024570/d1af7/Homee_Webhook_CGI_06.avif 460w", "/en/static/2ed2689dfaaaf3983604dd8477024570/abb2d/Homee_Webhook_CGI_06.avif 887w"],
              "sizes": "(max-width: 887px) 100vw, 887px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2ed2689dfaaaf3983604dd8477024570/a0b58/Homee_Webhook_CGI_06.webp 230w", "/en/static/2ed2689dfaaaf3983604dd8477024570/bc10c/Homee_Webhook_CGI_06.webp 460w", "/en/static/2ed2689dfaaaf3983604dd8477024570/1938b/Homee_Webhook_CGI_06.webp 887w"],
              "sizes": "(max-width: 887px) 100vw, 887px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2ed2689dfaaaf3983604dd8477024570/81c8e/Homee_Webhook_CGI_06.png 230w", "/en/static/2ed2689dfaaaf3983604dd8477024570/08a84/Homee_Webhook_CGI_06.png 460w", "/en/static/2ed2689dfaaaf3983604dd8477024570/eac55/Homee_Webhook_CGI_06.png 887w"],
              "sizes": "(max-width: 887px) 100vw, 887px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2ed2689dfaaaf3983604dd8477024570/eac55/Homee_Webhook_CGI_06.png",
              "alt": "Homee INSTAR Cameras",
              "title": "Homee INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "daynight-position",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#daynight-position",
        "aria-label": "daynight position permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Day/Night Position`}</h2>
    <p>{`We now set up our camera to interact with both Z-Wave devices - we trigger alerts on our camera, when the external motion sensor is tripped and trigger the external siren, when our camera raises an alarm. But `}<strong parentName="p">{`homee`}</strong>{` also offers us scheduled tasks, like triggering events at specific times of the day or at sunrise and sunset adjusted to our geo location.`}</p>
    <p>{`This can be used to change the direction our camera is looking in based on the time of day. E.g. you want your camera to watch the front gate as long as it is bright outside. But once it is getting dark swing it towards the carport and keep an eye on car instead? Ok, let's start writing a `}<strong parentName="p">{`Homeegram`}</strong>{` for that:`}</p>
    <div className="dangerbox">
    <br />
    <p><strong>Beachten Sie bitte</strong>, dass Sie die Kamera niemals vom Strom trennen sollten, wenn gerade ein Video auf der internen SD-Karte gespeichert wird. Wenn der unangekündigte, abprupte Neustart in einem sehr ungünstigen Moment erfolgt, kann es sein, dass die SD Karte nach dem Neustart nicht mehr lesbar ist und die Kamera diese nicht mehr <em>mounten</em> kann. In diesem Fall kann die Kamera in einer Bootschleife gefangen werden und würde erst wieder erreichbar sein, wenn man die defekte SD Karte aus der Kamera entfernt.</p>
    <p>Sie sollten immer zuerst die Alarmaufnahme auf die SD Karte deaktivieren, bevor Sie die Kamera vom Strom trennen - dies läßt sich natürlich auch über den homee automatisieren.</p>
    </div>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><img parentName="p" {...{
        "src": "./Homee_Power-Plug_01.png",
        "alt": "Homee INSTAR Cameras"
      }}></img></p>
    <p>{`As the `}<strong parentName="p">{`WHEN Condition`}</strong>{` we now have to choose `}<strong parentName="p">{`Sun Position`}</strong>{` (or choose `}<strong parentName="p">{`Time`}</strong>{` if you need specific times) and select `}<strong parentName="p">{`Sunrise`}</strong>{` first:`}</p>
    <p><img parentName="p" {...{
        "src": "./Homee_Power-Plug_02.png",
        "alt": "Homee INSTAR Cameras"
      }}></img></p>
    <p>{`On `}<strong parentName="p">{`Sunrise`}</strong>{` our camera should be pointed towards the front gate:`}</p>
    <p><img parentName="p" {...{
        "src": "./Homee_Power-Plug_03.png",
        "alt": "Homee INSTAR Cameras"
      }}></img></p>
    <p>{`The front gate is stored as position one that can set to be the park position of our camera with the following command:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`192.168.2.116/param.cgi?cmd=settimerpreset&-timerpreset_index=1&-usr=admin&-pwd=instar`}</code></pre></div>
    <p><img parentName="p" {...{
        "src": "./Homee_Power-Plug_04.png",
        "alt": "Homee INSTAR Cameras"
      }}></img></p>
    <p>{`The script to move our camera to the `}<strong parentName="p">{`Day Position`}</strong>{` is set. Now repeat those steps for the `}<strong parentName="p">{`Night Position`}</strong>{` for `}<strong parentName="p">{`Sunset`}</strong>{` using the following command to change the park position to stored position 3:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`192.168.2.116/param.cgi?cmd=settimerpreset&-timerpreset_index=3&-usr=admin&-pwd=instar`}</code></pre></div>
    <p><img parentName="p" {...{
        "src": "./Homee_Power-Plug_05.png",
        "alt": "Homee INSTAR Cameras"
      }}></img></p>
    <p>{`Make sure that you activated the Park Position inside your cameras `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Features/PTZ/"
      }}>{`PTZ Settings`}</a>{` and adjust the time it should remain in a place before returning to the position with the slider below:`}</p>
    <p><img parentName="p" {...{
        "src": "./Homee_Power-Plug_06.png",
        "alt": "Homee INSTAR Cameras"
      }}></img></p>
    <p>{`Every time our camera is now moved it will automatically return to the park position after a while. And the park position will now change according to the time of day.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "daynight-position-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#daynight-position-1",
        "aria-label": "daynight position 1 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Day/Night Position`}</h2>
    <p>{`We have now set up our camera to interact with both Z-Wave devices. We trigger alarms on our camera when the external motion sensor is triggered and the external siren when our camera triggers an alarm. However, `}<strong parentName="p">{`Homee`}</strong>{` also offers us timed tasks, such as triggering events at certain times of the day or at sunrise and sunset, which are adapted to our geographical location.`}</p>
    <p>{`This can be used, for example, to change the orientation of the camera depending on the time of day. For example, if you want your camera to watch the front gate while it is light outside. But as soon as it gets dark, you want it to pan towards the carport to monitor your car:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "859px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e5588a2f13b3660bb4fe5204ba2266ae/39a20/Homee_Day-Night_Schedule_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "54.78260869565218%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7CAAAOwgEVKEqAAAACP0lEQVQoz43Rz0+SARzH8edPsrKCiOVB5YcMl+DmoUtemr/aghkRskRmB5e6WaPy0EVmq+mGVls/1qp1oKTmpgIBIiAIDzyP8KBOj+/G05bz0PLwur73+e4rOBwOGpxOJ1NTU0QiEVZXV1lZ+U44HOZnJEIkvkFu/hMMzoN7Ae4uwPBL6JuD/iD0z/0leDwe3G43LpeL6elp0uk0mWyGRCJBLBollsmQDgbZerbI59dp3i8lWHyzRSiUZPn5Bq9eRE8Q/H6/Gro/Ps7Mo4fsiTLKr23yOzsUtrfJFotUw99IRX7gffcFV+gDD57M4wsuMfz2K87ljziWjgmjo6OEQiFmZ2fVsFKrqSSpgiiWKYsiyuEhyc1NRhy3uD3Qx1y7gZmeHvz3RvDcHMI12M+doQGVMDExQSAQYHJyksDTxyh5kVo0SzafYzOVIplMIUsS8Xgce3c3RrOZtq4u2q2dGE1mTB0WzJZjwtjYGD6fD6/Xq56slGWUXAmlXqeuKCiKwsHBAdFoDJvNhsFgoMNkwtxgNmMymU4QLBYLVquVttZWrvf2UqvIKKUK+UKBXDZLLpfj6OiIWOw42Aj9i6DVaNDpdJxpauKqrYtasaw+pSiWKJX+2N/fP31Qp9dzpaWF5vPnsdntyNUq1brCriwjyRKSJFGv11lfXz9d0N58Aav2Esaz57jRYaEqikiyjFgqUSgU1IWN4NraGna7/f/BTo2Wy3o92osarhmM7BWLVHZ31WWyLFOpVNTHNIKnWfgbVzxX8PszP3QAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e5588a2f13b3660bb4fe5204ba2266ae/e4706/Homee_Day-Night_Schedule_01.avif 230w", "/en/static/e5588a2f13b3660bb4fe5204ba2266ae/d1af7/Homee_Day-Night_Schedule_01.avif 460w", "/en/static/e5588a2f13b3660bb4fe5204ba2266ae/13b56/Homee_Day-Night_Schedule_01.avif 859w"],
              "sizes": "(max-width: 859px) 100vw, 859px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e5588a2f13b3660bb4fe5204ba2266ae/a0b58/Homee_Day-Night_Schedule_01.webp 230w", "/en/static/e5588a2f13b3660bb4fe5204ba2266ae/bc10c/Homee_Day-Night_Schedule_01.webp 460w", "/en/static/e5588a2f13b3660bb4fe5204ba2266ae/4e068/Homee_Day-Night_Schedule_01.webp 859w"],
              "sizes": "(max-width: 859px) 100vw, 859px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e5588a2f13b3660bb4fe5204ba2266ae/81c8e/Homee_Day-Night_Schedule_01.png 230w", "/en/static/e5588a2f13b3660bb4fe5204ba2266ae/08a84/Homee_Day-Night_Schedule_01.png 460w", "/en/static/e5588a2f13b3660bb4fe5204ba2266ae/39a20/Homee_Day-Night_Schedule_01.png 859w"],
              "sizes": "(max-width: 859px) 100vw, 859px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e5588a2f13b3660bb4fe5204ba2266ae/39a20/Homee_Day-Night_Schedule_01.png",
              "alt": "Homee INSTAR Cameras",
              "title": "Homee INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`As a `}<strong parentName="p">{`IF condition`}</strong>{` we now need to go to `}<strong parentName="p">{`Sun Position`}</strong>{` (or `}<strong parentName="p">{`Time`}</strong>{` if you need specific times) and select `}<strong parentName="p">{`Sunrise`}</strong>{` first:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "858px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7db291f2df970f2641f8b76a218df68d/42d54/Homee_Day-Night_Schedule_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAA7CAAAOwgEVKEqAAAAC+klEQVQ4y4WS208jVRzH518iXMKtBOSBQAz2Pu1022GY0gUW3JU22m4DimFvIAVfRBPefMb4aqI+EpHd9AK4iLuFAt1eaOmUhLhPfswc2gQTEh8+Oedk5nzO73e+R5qbm8MkEomwtrbG77u7pNIpdnZ22N7e5tXLl+xmkuS+/wU++xGe/QSRH/gnvHUn0sLCAvF4nFgsxmoiQf7snHzujDdv33J0dMTh60NOTnP8+VuS1ftRngTDPNPDPNXmeKp9coubtbS4uMjGxgarX62y9vU6V8UKxuEp74oFCoUC+XwewzDYf/0H6j0vms+Ldk/BZRtFdliR7U0+wu2w3gi3trbY3NwkkUiIzfV6nVKpKGT583Oq9TrZZIqlyWk+f/iIJ+EICzOzPJ6cIj45TXxyiuj0A54HNKSVlRXW19dZXl7mm+++xTgpUHv1F7n8GcfHxxxns1Qua2TTGb4M3Wd++gFLDx8xb0r0ILHgBDE9yKcTIZZ8fqRYLEo0GhWhPH/xAqN6Sb1yiVE3RLW1Wo3r62v2D/YZGxtDU1UmdB2vLOORZTE2kT0epP7+fgYHB+nq6sLpdlErlDHenHNydko2mxVV/v3+PQcHB+i6jqZp+AMBFEVB8fn+g09RkLq7u+nt7aWtrQ2rzUatXMHIFShflLm4uKBcLnN1dcXe3h5erxen04nD4cBms92J1NPTQ19fH62trdisVirVKlXjUoiKxSKlUkmElEwmxYahoSFGRkYYHh5mpMHwLaSBgQFMaWdnp/jRFFUqFSHL59+Jp2MKU6kUqqqKKmW3G6fLhV12Y5NlMXc1kAYGPhAys2XzBLNNU9jEXJvhpNNpwuEwH8/Ooo2PM6Fp/Nzeza9tXUypKmO6jj4+ftOyxWKhpaUFq9nyLVHzDk1hJpMhFAoRCAQIqCqq388XH44yP2oVczMo85tkBmKx9NHe3i7u6C5hs+WZmRmCwaBI2KsoOPx+gTlXGkhmdebT6ejo+F+hx+MRXdjt9puxgRmmtZHyv/es7w0c/94VAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7db291f2df970f2641f8b76a218df68d/e4706/Homee_Day-Night_Schedule_02.avif 230w", "/en/static/7db291f2df970f2641f8b76a218df68d/d1af7/Homee_Day-Night_Schedule_02.avif 460w", "/en/static/7db291f2df970f2641f8b76a218df68d/1f8f9/Homee_Day-Night_Schedule_02.avif 858w"],
              "sizes": "(max-width: 858px) 100vw, 858px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7db291f2df970f2641f8b76a218df68d/a0b58/Homee_Day-Night_Schedule_02.webp 230w", "/en/static/7db291f2df970f2641f8b76a218df68d/bc10c/Homee_Day-Night_Schedule_02.webp 460w", "/en/static/7db291f2df970f2641f8b76a218df68d/41e9e/Homee_Day-Night_Schedule_02.webp 858w"],
              "sizes": "(max-width: 858px) 100vw, 858px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7db291f2df970f2641f8b76a218df68d/81c8e/Homee_Day-Night_Schedule_02.png 230w", "/en/static/7db291f2df970f2641f8b76a218df68d/08a84/Homee_Day-Night_Schedule_02.png 460w", "/en/static/7db291f2df970f2641f8b76a218df68d/42d54/Homee_Day-Night_Schedule_02.png 858w"],
              "sizes": "(max-width: 858px) 100vw, 858px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7db291f2df970f2641f8b76a218df68d/42d54/Homee_Day-Night_Schedule_02.png",
              "alt": "Homee INSTAR Cameras",
              "title": "Homee INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`At `}<strong parentName="p">{`sunrise`}</strong>{` our camera should be pointed at the front gate:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "860px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c9068b59f6a6764a153ade347dac8795/914ae/Homee_Day-Night_Schedule_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "55.21739130434783%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7CAAAOwgEVKEqAAAACi0lEQVQoz2XR3UtTcRzH8fNH+SwKIiJoPuGFlfhUYQRB5ebc5jbnNAMfZ2YIkdVFOSFmWgRdKHgjogk+P8y5c7Yzt7PNdo7LbrR4x1lUiBcvPvxuPnx/369gMBjQGY1GBgcHWV5eZn1zg5WVFZaWllhb/crq1jri1AK/jO85757jZ+8nLmwfOH/g4eLhZUJXVxc2mw2r1crAwABBSSIaCuP3+/H5fOzu7SFKIv7dAG/nj3i5KPN0Icgz3fxVgtPpZGJigtHRUQaGBvkWiXF6IKPEFGKKQlRR0FSVAzHALWcPTc7HNLue0ODooc7iuEJwuVx4PB4mJyfTEyYSCVKpFLFYjHA4jCzLJJNJfAcH3G1poaW+ntbmJu40NtB4ve4KQS9xu9309/cz9nycRCCMtuZHjoSRRBFRFDk5OeHoyE+3rRNruxF7hwmH2USf05HOv289BZPJhM7Q1oarx0UiFieV1FA1FVVVUTWNs+8pDoMyvQvruBa3sS5sYZ7XbaZZ5rf+EQoLCykqKiInJ4eKqkqikkzKf4wUCiIGAoiSxI+zMw6lIK5pL85pL5YpL+YpL10znzF7vHRM/Sfk5+dTUFBAVlYW5dfKicjHpI7jxBPx9D51+pTS4RGzPWPM2IeZsY8waxvmS/cYHzuHmLMOMdf5h5CXl5cuzMjIoKysjPBxmOSpRjweJxqNpo+T1FSkvUM2+t6x3/kG6f44PsMLtsyv2Ta/ukTQS3Jzc9GLS0pKCIVC6asqikIkEkmnvsv9/X1MvU5677UyW1yM+3YLtpF+2h0WHtnNtNkttNnNCKWlpWRnZ5OZmXmpUP+qfl09NU1jZ3uH+hs3qaisorK2lurqGqorq6ipqr7kN4Ypf3e83GX1AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c9068b59f6a6764a153ade347dac8795/e4706/Homee_Day-Night_Schedule_03.avif 230w", "/en/static/c9068b59f6a6764a153ade347dac8795/d1af7/Homee_Day-Night_Schedule_03.avif 460w", "/en/static/c9068b59f6a6764a153ade347dac8795/384d4/Homee_Day-Night_Schedule_03.avif 860w"],
              "sizes": "(max-width: 860px) 100vw, 860px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c9068b59f6a6764a153ade347dac8795/a0b58/Homee_Day-Night_Schedule_03.webp 230w", "/en/static/c9068b59f6a6764a153ade347dac8795/bc10c/Homee_Day-Night_Schedule_03.webp 460w", "/en/static/c9068b59f6a6764a153ade347dac8795/31b17/Homee_Day-Night_Schedule_03.webp 860w"],
              "sizes": "(max-width: 860px) 100vw, 860px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c9068b59f6a6764a153ade347dac8795/81c8e/Homee_Day-Night_Schedule_03.png 230w", "/en/static/c9068b59f6a6764a153ade347dac8795/08a84/Homee_Day-Night_Schedule_03.png 460w", "/en/static/c9068b59f6a6764a153ade347dac8795/914ae/Homee_Day-Night_Schedule_03.png 860w"],
              "sizes": "(max-width: 860px) 100vw, 860px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c9068b59f6a6764a153ade347dac8795/914ae/Homee_Day-Night_Schedule_03.png",
              "alt": "Homee INSTAR Cameras",
              "title": "Homee INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The gate is saved as position 1, which can be set as the parking position of our camera with the following command (i.e. we can always move the camera and look around - it will always automatically move back to the parking position after an adjustable time):`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`192.168.2.116/param.cgi?cmd=settimerpreset&-timerpreset_index=1&-usr=admin&-pwd=instar`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "858px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/948694312a711d4d32540081d95216c8/42d54/Homee_Day-Night_Schedule_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "93.04347826086956%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAYAAACQjC21AAAACXBIWXMAAA7CAAAOwgEVKEqAAAADdUlEQVQ4y42U60ubZxjG3z9JBY+pUT/YquiMYjQHczaJE7biAV11qWdB2oloigcqdLIPfltFptEOPwhOBOni+klwMbGz1XgWE5ui/sbzuASphfnCj+d5H3iv57py37mVxsZGGhoa6OzsZG5uDr/fz9raGsvLy6yurvLW/ydv/1gl1jkDnT7w/AaNv0LT66+itLW10draisfjkSK7u7sEAn+zsbGRYOf9P/w+9AsD5ka8zicM2ZoZtrfcwWtvQenv72doaIj29nb+eveOi40PfAy+J7QdYnt7m1AoxHkkwouXYxRrSrA4rJhsZjTaMkorNAk0FRpKKstRXr36mXmfj46ODhn3IvaJo6MjDg8PE+v5+TkzL0Zoczh51tRMz3eP8bjcPHXX8tR1w4+13zJcZUAZGBjg+fNn9PX14V/3E10P8mFrm0Bwi83NTQKBgHT4ethLk8lMz/eP8bjdNFtt/GCz02Kzy7XZ7uCnsgqUrq4uuru7pcP19XWi4WMOwvvs7+9zcHAg1+jFBYODg+SoHlCQn8+j/HwKHz68w6OCApSioiIKCwvJy8tjcXGRzwdnsgjBYJCtrS3C4TCxWAyv10txSQlVOh1arZaysjI0XyDOlMzMTDIyMkhJTmbhzRtioX3COx/ZC4elO+kwGmVsbEx+YDQa0ev16HS6O4hzRa1Wk52dTWpqKgsLC0Q/fyL8n5BAODw7O2N0dJTq6mrcbje1tbW4XC65j+N0OuVlinCnUj0gOTmZ2dlZIucR2Ys7OzsSIXh6eiodGgwGampqcNhsuJxOKWS32xNIwczMLHJzc0lJSWFmZkbG29vbkwURxB2OjIxQWloqRTVGI5VGA3qdnqqqqgQycnp6uoyblJSEz+cjEolIwduRhcPx8XF0ej3VBgMv1Xm0lmow2u3YLBasVisWi+XGYU5OjvwN09LSmJ+fl038NcF4ZJPJREvxN7i1WvQmE9VGozxLCIqiqNU3RbktGO/B24LxD8tFZIOIfFNZIWQ2m+WFSlZWFiqV6l6Cosp1dXVYLRa5FxeIVUR2OBxyLwUF9xEUDV1ZWUl5ebnsyS8Rvfi/goKTkxMmJibkiOvt7ZV/1Z6engTxd+HyXoJi6kxNTclxdnl5ydXVFfHn+vpaIh4xpO8leHx8zOTkJNPT06ysrLC0tHQHMZzr6+v5F6A4F6WYq2jrAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/948694312a711d4d32540081d95216c8/e4706/Homee_Day-Night_Schedule_04.avif 230w", "/en/static/948694312a711d4d32540081d95216c8/d1af7/Homee_Day-Night_Schedule_04.avif 460w", "/en/static/948694312a711d4d32540081d95216c8/1f8f9/Homee_Day-Night_Schedule_04.avif 858w"],
              "sizes": "(max-width: 858px) 100vw, 858px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/948694312a711d4d32540081d95216c8/a0b58/Homee_Day-Night_Schedule_04.webp 230w", "/en/static/948694312a711d4d32540081d95216c8/bc10c/Homee_Day-Night_Schedule_04.webp 460w", "/en/static/948694312a711d4d32540081d95216c8/41e9e/Homee_Day-Night_Schedule_04.webp 858w"],
              "sizes": "(max-width: 858px) 100vw, 858px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/948694312a711d4d32540081d95216c8/81c8e/Homee_Day-Night_Schedule_04.png 230w", "/en/static/948694312a711d4d32540081d95216c8/08a84/Homee_Day-Night_Schedule_04.png 460w", "/en/static/948694312a711d4d32540081d95216c8/42d54/Homee_Day-Night_Schedule_04.png 858w"],
              "sizes": "(max-width: 858px) 100vw, 858px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/948694312a711d4d32540081d95216c8/42d54/Homee_Day-Night_Schedule_04.png",
              "alt": "Homee INSTAR Cameras",
              "title": "Homee INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The script for moving our camera to the `}<strong parentName="p">{`day position`}</strong>{` is set. Now repeat these steps for the `}<strong parentName="p">{`Night Position`}</strong>{` for `}<strong parentName="p">{`Sunset`}</strong>{` with the following command:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`192.168.2.116/param.cgi?cmd=settimerpreset&-timerpreset_index=3&-usr=admin&-pwd=instar`}</code></pre></div>
    <p>{`The command will then change the parking position to the stored position 3 and thus cause the camera to always automatically turn towards the carport:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "859px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/36c4506494482d26b28662dcc1fb72c8/39a20/Homee_Day-Night_Schedule_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.04347826086957%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAA7CAAAOwgEVKEqAAAADRklEQVQ4yzWT/U9bZRSA779EIEBqCLhEiU66OSbIYMAYOBiM0t62lBbLgHYDDQWhUzHoWGFsCME5zbpI+BaBIBDRAJKMgsO4SVu6ft1WgcfcWzzJk3Py/nDynPPmCDqdDpnm5mbcbjdra2ssLy8zPz/P4uIiK6srrPy0hNQ4CtoHYB6BhhGodsG1fqjsT+TrLmgYRbBYLJjNZqxWK0tLS/j9fvb399nd3cXj8bC7t8eL58/57f4M08Or/DC8ivvhGu77P/N08JSBZZ48WOPHHjdCe3s7DoeDpqYmtre34c8gYV8A/6GfQEDOh8AJg0NfU/jJF5T3DnD5zl2KnF9xqedLhcKePvI/7cdkakZwuVzKqHLDzc1N5IjH48Ri0mmOKW8um53ct9+iqqSYisICLudeID/nHQrOqSlQ55B3Xo3pjTcRurq66HQ4uGW3syE3fOYl6DvE6/Px98uXeL0+peEjp5MbVZUYtXUY6moxiVrMBhFjnYZ6rQa9qKWzpBShtbUVm81GS0sLW79vQUAiFokSlSTFTpINj4/4buRbmlyj2IYe0Tgwxs2hx7Q8/F6prQNjWIYe47zdjaBWq8nJySE7O5uFhQU4gmDgFV6vl4ODA6RYHE6O+ebJNKapdT6c/hX95HqCiXUMk+sYJn5BN7NBR98YQnp6OmlpaSQlJTE7NwcvQkRfhYjFYwlDKbFD9+e9NIl6blsauWVqwHGzmW6bHbvJRFuDGbulkb4PKhEyMzPJyMggNTWVObkhEA6HFbtgMMjx0TH/cszOZ6N4Gofw1A+wI947pR+P3oVHfw+PcZA/ytoShiqVSjGcmZ0Fb5SQP4A/cIgkSfwfox93cj2/BEtVHfUVN6ivqMFwtRpNUblCTXEF7efyEVSq13j9zBmSk5MZHx9PGEbCimUoFCIajcLJCXfb2lBfeJfSq1coKStVuFRcxMX898jNu8j59/MQz55FkEdNSUlRDCcmJ/jnr0P2nu2wsbWJZ2cHn89PNBJhuLuHa9XV1IkiGp0OrV6vUKvVotHqqBFF2kqvIGRlZSk7lD9mcmqKeDCCz+tVLkS+FJlwJMJT5x0+qtXQZTDSoRNxmi30Wq1K3ak30GE04ior5z93hPhGIuxZhQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/36c4506494482d26b28662dcc1fb72c8/e4706/Homee_Day-Night_Schedule_05.avif 230w", "/en/static/36c4506494482d26b28662dcc1fb72c8/d1af7/Homee_Day-Night_Schedule_05.avif 460w", "/en/static/36c4506494482d26b28662dcc1fb72c8/13b56/Homee_Day-Night_Schedule_05.avif 859w"],
              "sizes": "(max-width: 859px) 100vw, 859px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/36c4506494482d26b28662dcc1fb72c8/a0b58/Homee_Day-Night_Schedule_05.webp 230w", "/en/static/36c4506494482d26b28662dcc1fb72c8/bc10c/Homee_Day-Night_Schedule_05.webp 460w", "/en/static/36c4506494482d26b28662dcc1fb72c8/4e068/Homee_Day-Night_Schedule_05.webp 859w"],
              "sizes": "(max-width: 859px) 100vw, 859px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/36c4506494482d26b28662dcc1fb72c8/81c8e/Homee_Day-Night_Schedule_05.png 230w", "/en/static/36c4506494482d26b28662dcc1fb72c8/08a84/Homee_Day-Night_Schedule_05.png 460w", "/en/static/36c4506494482d26b28662dcc1fb72c8/39a20/Homee_Day-Night_Schedule_05.png 859w"],
              "sizes": "(max-width: 859px) 100vw, 859px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/36c4506494482d26b28662dcc1fb72c8/39a20/Homee_Day-Night_Schedule_05.png",
              "alt": "Homee INSTAR Cameras",
              "title": "Homee INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Make sure you have enabled the park position in the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Features/PTZ/"
      }}>{`PTZ Settings`}</a>{` of your camera and adjust the time after which it should move back to the park position:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "872px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8145c7e06e30f74543b4fdf5e7aaa645/65654/Homee_Day-Night_Schedule_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.52173913043478%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACjElEQVQoz22STU8TQRyH+wmMFwO+xIukChjkQygWMFUBPRAvCB48Efuy267S7m7FlggH0oYeQF4aYmJMoyWIEOHQrY2hKqBAoCRCopgYun2j3d3Z3dkZsy2NieGXyWRmkifP75+MwWK1koTNQj65P/Shcyh+b5C7O8B1+Lg7vli7l2v3cR0DsQ4f1+blOgdj3YHEw+eRp6y7v/+Zx+MxkCRJEATlYiLL+/MbwsJGfu57PvyZf51IzXzLR1ay4UTq7UpmZjX3bi0b/QFHwzFza/PttraeBz0GO+mw2uw0w+ZzOYwxQkjfxEMk5DCGGGsYShhBjDGE+h7nolWnq8+dqW4xNRmsdofVqsMHKV4CcqEoFAVRLBShokIVQg1pCEMNQQ0pCszIeH4xesForD55wtzSrJsfWe1uhuX5NABAFEUAAMLHRFbUr/taZG7xUl3t2apTpuvXDI8pqtdio1kPz/MAAEEQAAAYIX1VUj6JQI3vygtL0Yv1tVcaG1pbTAaKoiw2O1OCJUkqFouKohzj1TRYek98Wa5tbDDW1ZtMR7BNr51Oy7IsCIJUiqqq/+EQwhzAS9GP52tqjPWXb5hv6nBpZt2sKIooihBCVIruQxiqUMNYmZ3LTr6cTSqL0U9Xm5pazbe6urqPatMMm66Yy7VLU2uKCjMF/arl83I6LWC8ndxx9fV5vV6Ph9XhXovNxbCZTLbcTdO0ihn94qXN/cLOHzG/+7OwuYX39rbW1wmSZBjG7aaPYDfD5v59Elw2Y4y5ZCEUT4XXDn+/eX/gDxZehbdXVx0UxTIMTdMGp9NJkCThcA4PD4+MjAQCgWAwOFrJ5PhoaGJsamLsxdTUWCg0Pj3t9/tdLhddyl+yYD2r8lcWwAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8145c7e06e30f74543b4fdf5e7aaa645/e4706/Homee_Day-Night_Schedule_06.avif 230w", "/en/static/8145c7e06e30f74543b4fdf5e7aaa645/d1af7/Homee_Day-Night_Schedule_06.avif 460w", "/en/static/8145c7e06e30f74543b4fdf5e7aaa645/749ce/Homee_Day-Night_Schedule_06.avif 872w"],
              "sizes": "(max-width: 872px) 100vw, 872px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8145c7e06e30f74543b4fdf5e7aaa645/a0b58/Homee_Day-Night_Schedule_06.webp 230w", "/en/static/8145c7e06e30f74543b4fdf5e7aaa645/bc10c/Homee_Day-Night_Schedule_06.webp 460w", "/en/static/8145c7e06e30f74543b4fdf5e7aaa645/a8a2c/Homee_Day-Night_Schedule_06.webp 872w"],
              "sizes": "(max-width: 872px) 100vw, 872px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8145c7e06e30f74543b4fdf5e7aaa645/81c8e/Homee_Day-Night_Schedule_06.png 230w", "/en/static/8145c7e06e30f74543b4fdf5e7aaa645/08a84/Homee_Day-Night_Schedule_06.png 460w", "/en/static/8145c7e06e30f74543b4fdf5e7aaa645/65654/Homee_Day-Night_Schedule_06.png 872w"],
              "sizes": "(max-width: 872px) 100vw, 872px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8145c7e06e30f74543b4fdf5e7aaa645/65654/Homee_Day-Night_Schedule_06.png",
              "alt": "Homee INSTAR Cameras",
              "title": "Homee INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Every time our camera is moved now, it automatically returns to the parking position after a while. The parking position now changes to the entrance gate or our carport depending on the time of day.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "deactivate-alarm-when-you-are-home",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#deactivate-alarm-when-you-are-home",
        "aria-label": "deactivate alarm when you are home permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Deactivate Alarm when you are Home`}</h2>
    <p>{`Beside time schedule you can also use the provided `}<strong parentName="p">{`Mode System`}</strong>{` to trigger scripts on your `}<strong parentName="p">{`homee`}</strong>{` gateway. I want to create two scripts that activate the alarm schedule for my camera, when I set my `}<strong parentName="p">{`homee`}</strong>{` to `}<strong parentName="p">{`Away`}</strong>{` and to deactivate the schedule again, when I arrive back home and set it to `}<strong parentName="p">{`Home`}</strong>{`.`}</p>
    <p>{`Let's start with the `}<strong parentName="p">{`Deactivate Alarm when I am Home`}</strong>{` script:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "858px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/15525bc5bdd332477694ec6a0e192f86/42d54/Homee_Switch-Alarm_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "73.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAA7CAAAOwgEVKEqAAAADw0lEQVQ4y22T60+TZxjG3/9HPii6QaAEDWCZmCXASumBHmhBkQVdlYFAoeUwBTYHmzDYmDpCcKdPyxBNpjhYOAjDTROZlbYK2lYqLaUHoC38lvcl89Oe5Jf7zvPhyXU9130L6rEZ1L/OoLk1R9vkX8zPz7O4uMjs7CzT09MsPJjnwaOHuG7eg+oR+Ph7qP8BLKNQcQ0qr+8j9pZRBOPUErrJJbSTS9jmn+H1ePD5fLx48QK3243L6WTF68Ez95jZb39n4ueHjN1cYHx0gVvDcxLjw3OMjSxwd+g+QuXfXpr+8XPmkQ+7w89eKEpydZ1QeJPNzU2CwSDx3V3WV1aoudKPZmAYTf8NVFevoxJr33WUXwxR3H+Dyk96ECoWX/LTyw1all5jfewhGY+zm9wlthUjEolIJPb28D9bprZMyyl9GWdNRs7oy/bRlWEuLcGgUmIpKkKwPPbR9OQ15x756HCsEV8LseNe400wgM/rxevxEtvZIeB202VtpsFmp8Vmk7h0uRObvRWbzU5zWzvddfUIFX+uIqJfWKX1iZfEZpRkIEJiN0kyuc8e4He5+fTWLB3zz2n8w0H91DPqpsTqoH7SQe2Mm/ZfZhHy1HrytQZkhSVU1DWQjG6TDMcIbmwQWA8QCAQQzxuXm9bPezk/8DXnvxrkXP8ATd+NcGHwGz7qG6BmcAhr12cIGe++gyw9jYMHDqDVqEmEosRfrhOORohGo0TCYZJAwOli4uIV7jZc5V5dr8RkYz8TF7+UevF+quYSgiwri6PHjnH4yBF0ej27osW9PSldv9/P9va2pHB92cmdc53ctvRyp6qb21XdjJ/uYvx0J+OnOhmr6uY3QwtCVlYWGRkZHDp4EJVWTWIjQtwTkMZGSjiR2LfsdGLSqCgyaNGdNqE2G9BUGinSlnKypJD3SospK3wfQS6Xk5mZSVpaGgqFQgpDPKKyWCz2VqF/2YnFZEZnLMdsMmM2V1BuLMdgMGDQG9AajdSo1AiiuvT0dFJSUtBoNSRDURL+EL6119LGRMKRfctOFz2NjbS2tNDR3Ey71UpPZxeX7XY6rFbabHZ6LtQiyGQysrOzOZyais5UThxIxOOSuq2tLQlxbF65XNSW6aj6QMGHJUqqFSWcVanf9tUlSs4WFe//4dHsbFLT0zHk5REe/pGgy4f7uZvl5WVpn71eL08dDgoVCo7l5pKXn0+uXE7O8eNS/Y+c/HzRciZZMhmHxAdzcgj3XSP49BVr/jV8Xp+UtLjTDoeDUqWSfLmckwUFFJw48b/8C7nrZAxe1eoSAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/15525bc5bdd332477694ec6a0e192f86/e4706/Homee_Switch-Alarm_01.avif 230w", "/en/static/15525bc5bdd332477694ec6a0e192f86/d1af7/Homee_Switch-Alarm_01.avif 460w", "/en/static/15525bc5bdd332477694ec6a0e192f86/1f8f9/Homee_Switch-Alarm_01.avif 858w"],
              "sizes": "(max-width: 858px) 100vw, 858px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/15525bc5bdd332477694ec6a0e192f86/a0b58/Homee_Switch-Alarm_01.webp 230w", "/en/static/15525bc5bdd332477694ec6a0e192f86/bc10c/Homee_Switch-Alarm_01.webp 460w", "/en/static/15525bc5bdd332477694ec6a0e192f86/41e9e/Homee_Switch-Alarm_01.webp 858w"],
              "sizes": "(max-width: 858px) 100vw, 858px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/15525bc5bdd332477694ec6a0e192f86/81c8e/Homee_Switch-Alarm_01.png 230w", "/en/static/15525bc5bdd332477694ec6a0e192f86/08a84/Homee_Switch-Alarm_01.png 460w", "/en/static/15525bc5bdd332477694ec6a0e192f86/42d54/Homee_Switch-Alarm_01.png 858w"],
              "sizes": "(max-width: 858px) 100vw, 858px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/15525bc5bdd332477694ec6a0e192f86/42d54/Homee_Switch-Alarm_01.png",
              "alt": "Homee INSTAR Cameras",
              "title": "Homee INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The used CGI command for this web hook is:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`192.168.2.116/param.cgi?cmd=setscheduleex&-ename=md&-week0=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN&-week1=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN&-week2=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN&-week3=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN&-week4=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN&-week5=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN&-week6=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN&-usr=admin&-pwd=instar`}</code></pre></div>
    <p>{`Now to the `}<strong parentName="p">{`Activate Alarm when I am Away`}</strong>{` script:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "860px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/376ba1a4d4a2781a715441e7f3de3427/914ae/Homee_Switch-Alarm_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "85.21739130434783%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAACXBIWXMAAA7CAAAOwgEVKEqAAAAD4klEQVQ4y42T7U9TZxiHz9/kBowWhhvD0lM0moKADAQRhqUqcdraynxb0EIZKIsv2XTbB2KAzSWb8cOSTfcB2FBcXRV5aUsRHJTTnr7QNyjYazkHph/mkj3JlV/uL1fu+36eR9h39zcUau7+zslfXYw+HOexy8XY2Bijo6OMP3jIA7cL/61fwNwP1gGwDUJbPzTdhOavXnNiAKFxZIb9w9N8ODzNsQde5pcChKUgL168YH5+njm/n0VpmaXxCX7uH+GHH58w8N1jBhUG/2BwyMXQkIuB227u3LyPcPDxEqenglieSbQ9DZCIpcguyKwkEsTjcVZiMdazWYJ+Py3nHVR3f06Ns48qxyWqOi9T6bhERUc3exy9HLJ8gnDw0QI3ZmV6PUGOuhZYSa8CWZKpFLFYjFg0RublS6TpGSx1tbQeqOdYUyNHGvbT1tjA4fo6WmqqaaqtwW40IhydCGKZDKGkbSZEVIqxPrVIOBZFkiSkZYl0JkPI56Ozw0G7s4cznZ9xxtnDhb4rnHX2cM7Zw+mey/S2n0VouP+E+ntuau+5OTo8QSyegnSGjZcbbGxsopxl7yzOn8bpeDSPbcSHdcSHZSutw16Ojz3n4vfDCEU6Pe+LBjTvFbNrbyWR5SBZaQU5EiYUDCGHQqow6PVy7vynfNzViaXLgaWrk/NXr6h5wnGRY91OOux2BG3+OxRoNeS+/RaivpRIUIZYSt1hagulR3nGi9t+nYn2b3hy/AueWr5kwnqDCcsWtq+ZMnUjaDRa3i0qIic3F9FgICKHYW2dkBwiEAiQTqc3O5z2cP0jK1fMZ7je0s7V5lMqfQesXG44QW+jlZtVJoTS0lLy8/NVSnaUIAcksuEk6dVVMpnM6x3OeGiorsRYV0PtwXr2Ne5n34E6dldXsLNiD2KlkcZdZQh6vZ68vDyV4uJiwuGwKlCejByWScQTai3NeLCYWmlubcVkNmMyH1azxdTKIZOJJvNh7PUNCFqtloKCArZt24beIBIJBMkG44QjEVX+amSPlz6LFYfNTtdJG102G9c6LuC0K/VJHKdOcc18BEGRFRYWkqvsUBSJRKOqIJlMqiQSCXXs57Oz1BvLqdDrqSoro9JgYK9oUPMfqkQRQZFt375dHVncWUZ47i823AuvHnY0GmVtbQ3/3By7jUY+0OnQiSI79Po3IiiXodFoyMnJwbCzjFA8znpqlWRy8y/Htzr0+XyUG42U6nQYDAZ1mjchKDKtIszLw1BSwuLtO8T/9DM7N4vH41VFgaUAU1NTlJeXo9Pp/lP2b2FxMYu3vmXl2TyhsIwsb6LscXJy8n8J/wZZF8IzQTt5EgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/376ba1a4d4a2781a715441e7f3de3427/e4706/Homee_Switch-Alarm_02.avif 230w", "/en/static/376ba1a4d4a2781a715441e7f3de3427/d1af7/Homee_Switch-Alarm_02.avif 460w", "/en/static/376ba1a4d4a2781a715441e7f3de3427/384d4/Homee_Switch-Alarm_02.avif 860w"],
              "sizes": "(max-width: 860px) 100vw, 860px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/376ba1a4d4a2781a715441e7f3de3427/a0b58/Homee_Switch-Alarm_02.webp 230w", "/en/static/376ba1a4d4a2781a715441e7f3de3427/bc10c/Homee_Switch-Alarm_02.webp 460w", "/en/static/376ba1a4d4a2781a715441e7f3de3427/31b17/Homee_Switch-Alarm_02.webp 860w"],
              "sizes": "(max-width: 860px) 100vw, 860px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/376ba1a4d4a2781a715441e7f3de3427/81c8e/Homee_Switch-Alarm_02.png 230w", "/en/static/376ba1a4d4a2781a715441e7f3de3427/08a84/Homee_Switch-Alarm_02.png 460w", "/en/static/376ba1a4d4a2781a715441e7f3de3427/914ae/Homee_Switch-Alarm_02.png 860w"],
              "sizes": "(max-width: 860px) 100vw, 860px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/376ba1a4d4a2781a715441e7f3de3427/914ae/Homee_Switch-Alarm_02.png",
              "alt": "Homee INSTAR Cameras",
              "title": "Homee INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`192.168.2.116/param.cgi?cmd=setscheduleex&-ename=md&-week0=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-week1=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-week2=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-week3=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-week4=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-week5=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-week6=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-usr=admin&-pwd=instar`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "875px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/26033329baea691a91f5a3a3ad495d9e/71c8e/Homee_Switch-Alarm_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64.78260869565217%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACSElEQVQoz3WSTU8TURSGR90aYwS2Loz+BYOxrXFpjL+ABENSPwq0M1Sbom0sBYwuVIIxSjSRFMO0WkpbFyg00tKZthbdkEjUiF1AgCj9oO10Zu69M3PMTEgliG+enJw3J29u7j2Xct3o6/aOdg2HO32Tl/1sx53JDh/b6X/9P6x331y5F3EPjQwP+Skn3TvwfObRfP3+zK8Hs8XBt5u+2NpAbM0X1WuzadqH79YfJyt9g0+cjJ3qddA8lwYAWRSQ1CBEISooGhAV9pWi6oPu69csFjNl67EvpDkAQEhWNEDl7+LKlFiIS4WoVIiLP2ONlaiwEm38iBhNrPY1CPXlq13W8+azepjjeYRQpVzUAMjyKAocQqE2zB5BwWNNdmyoTRw/AEu01eowtZ+mbD0OjuMJxpVyUQUg38ZQqBVNn8RTx1HkRJMdO31KZFvgyy3mpsdiaqcYhuF5XpZloV4F42QcOIhDrXjyMGKPNtmxwRZpnIIlutfpMZvO6GGO0+8siiImKq6tyusLaDOrbeW1rcVd5KH0CUqf8SYPyobNzpgs5/6GBUGQJFFGhAAo+70zUgBrIMj6sP+258LFS3vCkiyJmqbocY3sRlNxbbvcqFer2yVCEMsGXS4XRdN0Om3sWZaJIfUfEUXFREGYEISQKAFAOBz2eDz6qrLZHACo6t5voRm1gdRXuUpgUfiQ36i/ePl75ClKpMLxeL/bTdGMMzAxkc/nOY7jDWUymdwuZbK5RJJPpDLzSS4zN8e9n/2YWng2Nub1ev8AT8RMJvbfnnwAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/26033329baea691a91f5a3a3ad495d9e/e4706/Homee_Switch-Alarm_03.avif 230w", "/en/static/26033329baea691a91f5a3a3ad495d9e/d1af7/Homee_Switch-Alarm_03.avif 460w", "/en/static/26033329baea691a91f5a3a3ad495d9e/c963a/Homee_Switch-Alarm_03.avif 875w"],
              "sizes": "(max-width: 875px) 100vw, 875px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/26033329baea691a91f5a3a3ad495d9e/a0b58/Homee_Switch-Alarm_03.webp 230w", "/en/static/26033329baea691a91f5a3a3ad495d9e/bc10c/Homee_Switch-Alarm_03.webp 460w", "/en/static/26033329baea691a91f5a3a3ad495d9e/a1ca4/Homee_Switch-Alarm_03.webp 875w"],
              "sizes": "(max-width: 875px) 100vw, 875px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/26033329baea691a91f5a3a3ad495d9e/81c8e/Homee_Switch-Alarm_03.png 230w", "/en/static/26033329baea691a91f5a3a3ad495d9e/08a84/Homee_Switch-Alarm_03.png 460w", "/en/static/26033329baea691a91f5a3a3ad495d9e/71c8e/Homee_Switch-Alarm_03.png 875w"],
              "sizes": "(max-width: 875px) 100vw, 875px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/26033329baea691a91f5a3a3ad495d9e/71c8e/Homee_Switch-Alarm_03.png",
              "alt": "Homee INSTAR Cameras",
              "title": "Homee INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Those two scripts will completely wipe the alarm schedule - not allowing alarms to be triggered - when your `}<strong parentName="p">{`homee`}</strong>{` is set to `}<strong parentName="p">{`Home`}</strong>{`. And once you set it to `}<strong parentName="p">{`Away`}</strong>{` the schedule will be filled for a 24/7 surveillance:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "857px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b43d6829ec9884699bc02889125ef2af/3cd52/Homee_Switch-Alarm_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "38.69565217391304%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAA7CAAAOwgEVKEqAAAABq0lEQVQoz02Ry2oUURCG+2V0oYJB3LkRF24EceHKjUKiu4Ag7nwQH8EHcOEFiS7GEJiZ6MiQQU0WGZPjdPf05Vz69GVmPqkzTLCgqP+c0/X3X/VHj97uI7n7acBwqvDWkGUZ3nustTjnAnbWUlVVSLkry5KiKEL9P6MnvV887v1kZ/83hyrFG815HCMhzW3brnFdhypn+YExhjzPA+kmA+HLccyro4Ttw3O+ZYb65Jjy/UeExmiNMZbKOfS7D7SVp/IeXerQLEqds1hn0VoH0uj1yZw305ynwz8MZxkra6iThDhNqeuapmnWY89mpElCXhR0XRfulFLEak4a52E9ojp68eMvz0eK3e+KgUphtaQD+v1+GG25XK4rMJkckSRJGN1VjiwtmEwPOFYjumaJsYZou3/KzmDKs8MzhmoObRMaxuNxULdYLIIaAuEkqJCQMSVO3QFndhSwNiXR7QcPuXXvPjfu3OVL72t4WK1WbELGE4VC3NR1MEWwGNZ1Lbb0WO1pBRtLdHPrOlvXrnLl8iU+7+0FEiGQBlEoVfZzsUvvL1wWcjFl860Y8w9wiUrW3jbrdwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b43d6829ec9884699bc02889125ef2af/e4706/Homee_Switch-Alarm_04.avif 230w", "/en/static/b43d6829ec9884699bc02889125ef2af/d1af7/Homee_Switch-Alarm_04.avif 460w", "/en/static/b43d6829ec9884699bc02889125ef2af/ab1cf/Homee_Switch-Alarm_04.avif 857w"],
              "sizes": "(max-width: 857px) 100vw, 857px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b43d6829ec9884699bc02889125ef2af/a0b58/Homee_Switch-Alarm_04.webp 230w", "/en/static/b43d6829ec9884699bc02889125ef2af/bc10c/Homee_Switch-Alarm_04.webp 460w", "/en/static/b43d6829ec9884699bc02889125ef2af/a145c/Homee_Switch-Alarm_04.webp 857w"],
              "sizes": "(max-width: 857px) 100vw, 857px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b43d6829ec9884699bc02889125ef2af/81c8e/Homee_Switch-Alarm_04.png 230w", "/en/static/b43d6829ec9884699bc02889125ef2af/08a84/Homee_Switch-Alarm_04.png 460w", "/en/static/b43d6829ec9884699bc02889125ef2af/3cd52/Homee_Switch-Alarm_04.png 857w"],
              "sizes": "(max-width: 857px) 100vw, 857px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b43d6829ec9884699bc02889125ef2af/3cd52/Homee_Switch-Alarm_04.png",
              "alt": "Homee INSTAR Cameras",
              "title": "Homee INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      